import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import MenuList from '@material-ui/core/MenuList';
import AuthContext from '../../../../services/auth';
import { findListItem } from '../../../../utils/utils';

const Menu = ({ list, component, noAuthCheck }) => {
  const auth = useContext(AuthContext);
  return (
    <MenuList>
      {list.map((menuItem, index) => {
        const isAuthorized =
          noAuthCheck ||
          findListItem(
            menuItem.roles,
            auth.auth,
            [menuItem.module, ...(menuItem.dependency || '')],
            auth.enterpriseFeatureDto,
            menuItem
          );
        if (isAuthorized) {
          return component(menuItem, index);
        } else {
          return null;
        }
      })}
    </MenuList>
  );
};

Menu.propTypes = {
  list: PropTypes.array
};

Menu.defaultProps = {
  list: []
};

export default Menu;
