import { Field } from 'formik';
import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ROLES } from '@/config/constants/keys';
import { getCompanyTimeZone, getFilterLocaleValues, hasRestrictedRole, hasValue, isValidObject } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const UnassignedEntry = ({
  i18n,
  unAssignedEntries,
  id,
  saveUnAssignedEntries,
  setUdAssignEntryList,
  callback,
  apiStatus,
  distance,
  Styled,
  selectedDriverProfile,
  saveIconClicked,
  setSaveIconClicked,
  selectedId,
  setSelectedId,
  errorMsg, setError,
  setDataSaved,
  logDate,
  dataSaved
}) => {
  useEffect(() => {
    if (saveIconClicked && selectedId === id && document.getElementsByClassName('udAssignSubmit' + selectedId)) {
      document.getElementsByClassName('udAssignSubmit' + selectedId)[0].click();
      //  setSelectedId(null);
      setSaveIconClicked(false);
    }
  }, [saveIconClicked])

  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM])
  const unAssignedEntryForm = () => {
    const apiId = 'unAssignedEntryForm';
    const hasUpdateunAssignedEntryApiStatus = hasValue(
      apiStatus.status,
      'api',
      apiId
    );
    return (
      <>
        <Form
          type="server"
          direction="row"
          initialValues={{ time_from: '' }}
          RenderComponent={RenderComponent}
          handleOnSubmit={(fields) => {
            saveUnAssignedEntries({
              apiId,
              data: { id: id, tab: selectedDriverProfile },
              callback: callback
            });
          }}
          customProps={{
            hasUpdateunAssignedEntryApiStatus,
            setUdAssignEntryList,
            unAssignedEntries,
            id
          }}
        />
      </>
    );
  };

  const RenderComponent = ({
    handleEdit,
    validateForm,
    customProps,
    submitAction
  }) => {
    const [list, setList] = useState([]);
    const [companyCurrentDate, setCompanyCurrentDate] = useState('');
    const [drivers, setDrivers] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const { id, unAssignedEntries } = customProps;
    const getDriverIdFromName = (list) => {
      const source = Object.keys(list);
      for (let index = 0; index < source.length; index++) {
        const element = list[source[index]];
        if (element === 'Unidentified Driver') return source[index];
      }
    };
    const convertTZ = (tzString) => {
      let date = new Date()
      let companyTimeZone = getCompanyTimeZone()
      let companyDate = companyTimeZone ? date.toLocaleString("en-US", { timeZone: companyTimeZone }).split(',')[0] : date.toLocaleString("en-US", {}).split(',')[0];
      setCompanyCurrentDate(companyDate)
      if (new Date(companyDate).getTime() === new Date(logDate).getTime()) {
        setValidationMessage(i18n.t('Assignment of the logs to the current day is restricted!'))
        setIsValid(false)
      }
    }
    useEffect(() => {
      const data = unAssignedEntries[id] || {};
      const driversList = Object.keys(data.companyDrivers || {}).map((key) => {
        const item = data.companyDrivers[key];
        return {
          label: item,
          value: key
        };
      });
      const filteredList = filteredListUdLog()
      const finalList = filteredList.filter((item) => isValidObject(item));
      setList(finalList || []);
      convertTZ()
      setDrivers(driversList);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unAssignedEntries]);

    useEffect(() => {
      const container = document.getElementById('tdEntry' + id);
      const gap = (window.$environment.SERVICE_PROVIDER === 'spireon' || window.$environment.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'nauto') ? 68 : 130;
      let source = document.getElementById('entry' + id);
      let calHt = window.$environment.SERVICE_PROVIDER === 'clutch' || window.$environment.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'nauto' || window.$environment.SERVICE_PROVIDER === 'webfleet' || window.$environment.SERVICE_PROVIDER === 'riskTheory' ? 165 : 130;
      let calHtMul =
        window.$environment.SERVICE_PROVIDER === 'clutch' || window.$environment.SERVICE_PROVIDER === 'riskTheory' ? 52 : 45;
      if (
        container &&
        list?.filter((item) => item.status === 'DRIVING').length
      ) {
        container.height = (
          calHtMul * list?.filter((item) => item.status === 'DRIVING').length +
          calHt
        ).toString();
        source.style.display = 'block';
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list]);
    const { i18n } = useTranslation();
    const [isValid, setIsValid] = useState(true);
    const generateRows = (handleEdit) => {
      const generatedUi = list
        ?.filter((item) => item.status === 'DRIVING')
        ?.map((item) => {
          let result = {};
          result.time_from = (
            <FormStyle.default.FieldsWrapper width="100%">
              <FormStyle.default.TextWrapper mode="light">
                {item.time_from}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );
          result.time_to = (
            <FormStyle.default.FieldsWrapper width="100%">
              <FormStyle.default.TextWrapper mode="light">
                {item.time_to}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );
          result.distance = (
            <FormStyle.default.FieldsWrapper width="50px">
              <FormStyle.default.TextWrapper mode="light">
                {typeof item.distance === "number" ? Number.parseFloat(item.distance).toFixed() : item.distance}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );
          result.location = (
            <FormStyle.default.FieldsWrapper width="100%">
              <FormStyle.default.TextWrapper mode="light">
                <Field
                  name="location"
                  handleOnChange={(event) => {
                    handleEdit(event, {
                      field: 'location',
                      id: item.id,
                      logId: id,
                      callback: updateField
                    });
                  }}
                  as={Input}
                  mode="dark"
                  placeholder={i18n.t('common.location')}
                  value={item.location}
                  type="text"
                  disabled={item.udReadOnly || viewOnly}
                />
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );
          result.driverId = (
            <FormStyle.default.FieldsWrapper width="100%">
              <FormStyle.default.TextWrapper>
                {!viewOnly ?
                  (<Field
                    name="driverId"
                    onChange={(event) =>
                      handleEdit(event, {
                        field: 'driverId',
                        id: item.id,
                        logId: id,
                        callback: updateField
                        // needFullUpdate: true
                      })
                    }
                    onBlur={(event) => {
                      updateField({
                        data: item.driverId,
                        field: 'driverId',
                        id: item.id,
                        logId: id
                      });
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={getFilterLocaleValues([item.driverId], i18n)}
                    type=""
                    suggestions={getFilterLocaleValues(drivers, i18n)}
                    className="Selectclass"
                    disabled={item.udReadOnly || viewOnly}
                  />) : item?.driverId?.label ? item.driverId.label : '-'
                }
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );
          result.coDriverId = (
            <FormStyle.default.FieldsWrapper width="100%">
              <FormStyle.default.TextWrapper>
                {!viewOnly ?
                  <Field
                    name="coDriverId"
                    onChange={(event) =>
                      handleEdit(event, {
                        field: 'coDriverId',
                        id: item.id,
                        logId: id,
                        callback: updateField
                        // needFullUpdate: true
                      })
                    }
                    onBlur={(event) => {
                      updateField({
                        data: item.coDriverId,
                        field: 'coDriverId',
                        id: item.id,
                        logId: id
                      });
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={getFilterLocaleValues([item.coDriverId], i18n)}
                    type=""
                    suggestions={getFilterLocaleValues(drivers, i18n)}
                    className="Selectclass"
                    disabled={item.udReadOnly || viewOnly}
                  /> : item?.coDriverId?.label ? item.coDriverId.label : '-'
                }
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );

          result.note = (
            <FormStyle.default.FieldsWrapper width="100%">
              <FormStyle.default.TextWrapper mode="light">
                <Field
                  name="note"
                  handleOnChange={(event) =>
                    handleEdit(event, {
                      field: 'note',
                      id: item.id,
                      logId: id,
                      callback: updateField
                      // needFullUpdate: false
                    })
                  }
                  as={Input}
                  mode="dark"
                  placeholder={i18n.t('common.note')}
                  value={item.note}
                  type="text"
                  disabled={item.udReadOnly || viewOnly}
                  onBlur={(event) => {
                    updateField({
                      data: event,
                      field: 'note',
                      id: item.id,
                      logId: id
                    });
                  }}
                />
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );
          result.status = (
            <FormStyle.default.FieldsWrapper width="100%">
              <FormStyle.default.TextWrapper mode="light">
                {i18n.t(item.status)}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          );

          return result;
        });
      return generatedUi;
    };

    const updateField = (payload) => {
      let source = [...list];
      source = source.map((item) => {
        if (
          item.id === payload.id ||
          (payload.needFullUpdate &&
            !item[payload.field]?.label &&
            (payload.field === 'driverId' || payload.field === 'coDriverId'))
        ) {
          item[payload.field] = payload.value;
        }
        if (
          payload.data &&
          !item[payload.field] &&
          (payload.field === 'location' || payload.field === 'note')
        ) {
          item[payload.field] = payload.data;
        }
        if (
          payload.data &&
          !item[payload.field]?.value &&
          (payload.field === 'driverId' || payload.field === 'coDriverId')
        )
          item[payload.field] = payload.data;

        return item;
      });
      setList(source);
    };
    const filteredListUdLog = () => {
      const data = unAssignedEntries[id] || {};
      const filteredList = Object.keys(data.dailyLogEntries || {}).map(
        (key) => {
          let item = data.dailyLogEntries[key];
          item.driverId = item?.driverId?.value
            ? item.driverId
            : item?.driverId === 0 || item?.driverId?.value === 0
              ? {
                value: 0,
                label: 'Please Select'
              }
              : {
                value: item.driverId,
                label: data.companyDrivers[item.driverId]
              };
          item.key = item.id;
          item.coDriverId =
            item?.coDriverId?.value || item.coDriverId
              ? {
                label:
                  unAssignedEntries[id].companyDrivers[
                  item?.coDriverId?.value || item.coDriverId
                  ],
                value: item?.coDriverId?.value || item.coDriverId
              }
              : '';
          return item;
        }
      );
      return filteredList;
    }
    return (
      <Styled.UnidentifiedSection>
        <div className="floatingudTable" style={{}}>
          <Styled.UdLists>
            <Table
              type="client"
              title=""
              columns={[...columns]}
              data={generateRows(handleEdit)}
              emptyMessage={''}
              itemsPerPage={15}
              actions={[]}
              innerTable
              i18n={i18n}
              noPagination
            />
          </Styled.UdLists>
          <div style={{ display: 'flex', justifyContent: 'end' }} className="footer">
            {window.$environment.CURRENT_LAYOUT === 'SpireonNew' ?
              (errorMsg && (selectedId === id) && <Message type="error" message={errorMsg} />) :
              (!isValid && <Message type="error" message={validationMessage} />)}
            {!viewOnly &&
              (<FormStyle.default.ButtonsWrapper className="saveButton">
                <Button
                  type="submit"
                  label={i18n.t('common.save')}
                  disabled={new Date(companyCurrentDate).getTime() === new Date(logDate).getTime()}
                  className={"udAssignSubmit" + id}
                  onClick={() => {
                    const spListData = selectedId && unAssignedEntries[selectedId]
                    const listData = window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? spListData ? filteredListUdLog() : list : list;
                    let validData = listData.filter(
                      (ud) => ud.udReadOnly === false && ud.status === 'DRIVING'
                    );
                    if (!validData.length) {
                      callback('error', i18n.t('No valid entries to save'));
                    } else {
                      const validateResponse = validateForm();
                      validateResponse.then((data) => {
                        let validity = true;
                        for (let i = 0; i < validData.length; i++) {
                          const item = validData[i];
                          if (
                            ((i !== 0 && !validity) || i == 0) &&
                            (!item.driverId.value || !item.note)
                          ) {
                            validity = false;
                            if (!item.driverId.value) {
                              setValidationMessage(
                                i18n.t('Please fill in atleast one assigned driver and its corresponding notes')
                              );
                            } else {
                              setValidationMessage(
                                i18n.t('Please fill in notes')
                              );
                            }
                            if (window.$environment.CURRENT_LAYOUT === 'SpireonNew') {
                              if (!item.driverId.value) {
                                setError(
                                  i18n.t('Please fill in atleast one assigned driver and its corresponding notes')
                                );
                              } else {
                                setError(
                                  i18n.t('Please fill in notes')
                                );
                              }
                            }
                          } else if (
                            item.note?.length < 4 &&
                            !item.udReadOnly &&
                            item.status === 'DRIVING'
                          ) {
                            validity = false;
                            setValidationMessage(
                              'Please enter atleast 4 characters in notes'
                            );
                            if (window.$environment.CURRENT_LAYOUT === 'SpireonNew')
                              setError('Please enter atleast 4 characters in notes');
                          }
                          else if (item.coDriverId && item.driverId && item.driverId.value == item.coDriverId.value) {
                            validity = false;
                            setValidationMessage(
                              "Driver and Co-Driver cannot be same, Please fill in different values"
                            );
                            if (window.$environment.CURRENT_LAYOUT === 'SpireonNew')
                              setError('Driver and Co-Driver cannot be same, Please fill in different values');
                          }
                        }
                        setIsValid(validity);
                        if (validity) {
                          setUdAssignEntryList({ list, id });
                          submitAction(data);
                          setSelectedId(null);
                        }
                      });
                    }
                  }}
                />
              </FormStyle.default.ButtonsWrapper>)
            }
          </div>
        </div>
      </Styled.UnidentifiedSection>
    );
  };

  const columns = [
    {
      id: 'time_from',
      numeric: false,
      disablePadding: true,
      label: i18n.t('common.startTime'),
      needSorting: false
    },
    {
      id: 'time_to',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.endTime'),
      needSorting: false
    },
    {
      id: 'distance',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.distance'),
      needSorting: false
    },
    {
      id: 'location',
      numeric: false,
      disablePadding: false,
      label: i18n.t('common.location'),
      needSorting: false
    },
    {
      id: 'driverId',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.assignedDriver'),
      needSorting: false
    },
    {
      id: 'coDriverId',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.coDriver'),
      needSorting: false
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.notes'),
      needSorting: false
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.status'),
      needSorting: false
    }
  ];
  return unAssignedEntryForm(setUdAssignEntryList);
};

export default UnassignedEntry;
