export default {
  'Select All': 'Select All',
  'This field is required': 'This field is required',
  'Add New Company': 'Add New Company',
  'COMPANY REGISTRATION': 'COMPANY REGISTRATION',
  'COMPANY INFORMATION': 'COMPANY INFORMATION',
  'SUBSCRIPTION LIST': 'SUBSCRIPTION LIST',
  Account: 'Account',
  'Switch to Old View': 'Switch to Old View',
  Messaging: 'Messaging',
  Logout: 'Logout',
  'MANAGE LOGS': 'MANAGE LOGS',
  Date: 'Date',
  'Total Hours Worked': 'Total Hours Worked',
  'Total Distance': 'Total Distance',
  'Driver Name': 'Driver Name',
  'Vehicle Name': 'Vehicle Name',
  Upload: 'Upload',
  'No file selected': 'No file selected',
  Cancel: 'Cancel',
  Save: 'Save',
  Reset: 'Reset',
  'The email is not valid': 'The email is not valid',
  'LOG BOOK': 'LOG BOOK',
  Description: 'Description',
  'Date (in UTC)': 'Date (in UTC)',
  'VIEW DOCUMENT': 'VIEW DOCUMENT',
  NEXT: 'NEXT',
  PREV: 'PREV',
  'DAILY LOG HISTORY': 'DAILY LOG HISTORY',
  Actions: 'Actions',
  Annotation: 'Annotation',
  'Modified By': 'Modified By',
  Version: 'Version',
  ELD: 'ELD',
  OTHER: 'OTHER',
  'LOG IN': 'LOG IN',
  REGISTRATION: 'REGISTRATION',
  Forgot: 'Forgot your password?',
  ForgotHeading: 'We’ll help you reset it and get back on track.',
  viewSample: 'View Sample',
  'Forgot password?': 'Forgot password?',
  'Remember me': 'Remember me',
  backLogin: 'Back to Login',
  emailAddress: 'Email address',
  'Log in': 'Log in',
  'If your email address is registered with us, you will receive a password recovery link at your email address.': 'If your email address is registered with us, you will receive a password recovery link at your email address.',
  'FDCSA info':
    'NB: This will send files to FMCSA via email and or Web Services as outlined in Appendix A to Subpart B of Part 395',
  'mail@gmail.com': 'mail@gmail.com',
  Password: 'Password',
  email: 'email',
  Email: 'Email',
  'EDIT DAILY LOG': 'EDIT DAILY LOG',

  'Email or Employee Id': 'Email or Employee Id',
  'Send a Message': 'Send a Message',
  'Select Drivers here': 'Select Drivers here',
  Type: 'Type',
  'Select Drivers here...': 'Select Drivers here...',

  Logs: 'Logs',
  Export: 'Export',
  'Start Time': 'Start Time',
  'End Time': 'End Time',
  'Assigned Driver': 'Assigned Driver',
  'Co-Driver': 'Co-Driver',
  Status: 'Status',
  PREVIOUS: 'PREVIOUS',
  'Last Name': 'Last Name',
  apology: 'Our apologies',
  'page moved': 'That page was moved or deleted.',
  'send feedback': 'Please send us feedback',
  here: 'here',
  footer: {
    address: {
      one: 'Corporate Office',
      two: 'Office',
      three: 'Online',
      onedetails: 'Houston, TX',
      twodetails: '844-636-1360',
      threedetails: 'info@gorillasafety.com'
    },
    patent: 'Patent pending',
    copyright: 'Copyright 2020',
    PrivacyLegal: 'Privacy & Legal',
  },
  camera: {
    listHeading: "Camera",
    camera_serial_no: 'S. No.',
    cameraStatus: 'Status',
    vehicles: 'Vehicle',
    simState: 'Sim Status',
    dateTimeFrom: 'Date/Time From',
    dateTimeTo: 'Date/Time To',
    lastConnectTime: 'Last Connect Time',
    lastDisconnectTime: 'Last Disconnect Time',
    vehicle: 'Vehicle',
    event_type: 'Event Type',
    camera_view: 'View',
    event_level: 'Level',
    date: 'Date',
    time: 'Time',
    cycle: 'Cycle',
    startDate: 'Start Date',
    endDate: 'End Date',
    tabs: {
      events: 'Events',
      dataUsage: 'Data Usage'
    }
  },
  cameraEvents: {
    listHeading: 'Camera Events',
    event_count: "Event Count",
    event_type: 'Event Type',
    last_occurrence: 'Last Occurrence Date',
    vehciles: 'Vehicles',
    totalcounts: 'Total Event Count',
    eventsTypes: 'Event Types'
  },
  resetPassword: 'Reset Password',
  manageProfile: { editProfile: { heading: 'EDIT PROFILE' } },
  common: {
    en: 'English',
    qrCode: 'Enter Code',
    getaQuotes: 'Get a Quote',
    authenticateMFA: 'Authenticate',
    notificationList: 'Notification List',
    hideNotification: 'Hide Notification',
    enableMFA: 'Enable MFA',
    mfacode: 'MFA code',
    authenticate: 'Authenticate',
    disableMFA: 'Disable MFA',
    customStateErrorMessage: 'Invalid Jurisdiction. please select Jurisdiction',
    setUpMFA: "Set Up MFA",
    removeMFA: "Remove MFA",
    es: 'Spanish',
    name: 'Name',
    policy: 'Policy',
    language: 'Language',
    disablePopup: "Yes, Disable",
    enablePopup: "Yes, Enable",
    company: 'Company',
    camera: 'Camera',
    dotNumber: 'DOT No.',
    address: 'Address',
    city: 'City',
    zipCode: 'Zip Code',
    date: 'Date',
    fullName: 'Full Name',
    hours: 'Hours',
    hoursDriving: 'Hours Driving',
    radiusSection: 'Radius Settings',
    manageDailyDocuments: 'MANAGE DAILY DOCUMENTS',
    alerts: 'Alerts',
    alertSp: 'Compliance Notifications',
    submit: 'Submit',
    type: 'Type',
    account: 'Account',
    drivers: 'Driver',
    driver: 'Driver',
    datePicker: 'mm/dd/yyyy',
    unhandled: 'Unhandled',
    dateTo: 'Date To',
    dateFrom: 'Date From',
    hoursWorked: 'Hours Worked',
    vehicle: 'Vehicle',
    distance: 'Distance',
    date: 'Date',
    cycleRule: 'Cycle Rule',
    configuration: 'Configuration',
    emailAlerts: 'Email Alerts',
    alertToEmailInfo: "If no events are selected, it will be considered that all events should be sent.",
    tools: 'Tools',
    tableEmptyMessage: 'No data available in table',
    edit: 'Edit',
    'resetPassword': 'Reset Password',
    cameraStatus: 'Camera Status',
    liveCamera: 'Live-Streaming',
    history: 'History',
    view: 'View',
    totalHoursWorked: 'Total Hours Worked',
    totalDistance: 'Total Distance',
    save: 'Save',
    location: 'Location',
    driverId: 'driverId',
    coDriverId: 'coDriverId',
    note: 'note',
    coDriver: 'Co-Driver',
    startTime: 'Start Time',
    endTime: 'End Time',
    assignedDriver: 'Assigned Driver',
    annotation: 'Annotation',
    status: 'Status',
    fieldRequiredMessage: 'This field is required.',
    passwordIsNotMatching: 'The confirm password is not match with password',
    emailValidationMessage: 'Please enter valid email format',
    validNumberMessage: 'Please enter a valid number',
    positiveNumberMessage: 'Must be greater than zero',
    validName: 'Please enter no more than 10 characters',
    validName10000: 'Maximum allowed count is 10000',
    description: 'Description',
    previous: 'Previous',
    next: 'Next',
    version: 'Version',
    modifiedBy: 'Modified By',
    actions: 'Actions',
    cancel: 'Cancel',
    driverName: 'Driver Name',
    vehicleName: 'Vehicle Name',
    upload: 'Upload',
    clear: 'Clear',
    clearAll: 'Clear All',
    userInformation: 'User Information',
    document: 'Document',
    documents: 'Documents',
    userCredentials: 'User Credentials',
    firstName: 'First Name',
    lastName: 'Last Name',
    lastUpdated: 'Last Updated',
    email: 'Email',
    hireDate: 'Hire Date',
    role: 'Role',
    viewAll: 'View All',
    followVehicle: 'Follow Vehicle',
    fleetManager: 'Fleet Manager',
    mechanic: 'Mechanic',
    admin: 'Admin',
    superAdmin: 'Super Admin',
    underWriter: 'Underwriter',
    lossControl: 'Loss Control',
    radiusTolerance: 'Radius Tolerance',
    claimAdjuster: 'Claim Adjuster',
    employeeId: 'Employee Id',
    departments: 'Departments',
    vehicleId: 'Vehicle ID',
    licenceNumber: 'License Number',
    state: 'State',
    pageNotFound: 'Page not found',
    unAuthorized: 'Un authorized',
    export: 'Export',
    'Confirm Password': 'Confirm Password',
    cellPhone: 'Cell Phone',
    userMainInformation: 'User Main Information',
    logHistory: 'DAILY LOG HISTORY',
    password: 'Password',
    ok: 'OK',
    driverPhone: 'Driver Phone',
    delete: 'Delete',
    exportAll: 'Export All',
    confirmPasswordMessage: 'Please enter the same value again.',
    region: 'Region',
    terminal: 'Terminal',
    department: 'Department',
    dotOfficerCode: 'Dot Officer Code',
    pleaseSelect: 'Please Select',
    resetPassword: 'Reset password',
    bulkDownloadReport: 'Bulk Download Reports',
    importUser: 'Import User',
    import: 'Import',
    template: 'Template',
    add: 'Add',
    sureConfirm: 'Are you sure?',
    recoverConfirm: ' You will not be able to recover this vehicle!',
    viewMore: 'View more',
    viewLess: 'View Less',
    timzone: 'TimeZone',
    deviceType: 'Device Type',
    policiesAndProcedures: 'Policies and Procedures',
    otherContact: 'Other Contact',
    noBreadcrumbData: 'No Data Found to plot the BreadCrumb',
    make: 'Make',
    plateNumber: 'Plate Number',
    gvw: 'GVW',
    assignedVehicle: 'Assigned Vehicle',
    nextMaintenanceDate: 'Next Maintenance Date',
    nextMaintenanceMileage: 'Next Maintenance Mileage',
    notes: 'Notes',
    deviceSn: 'Device SN',
    trailerInformation: 'Trailer Information',
    vehicleInformation: 'Vehicle Information',
    currentOdometer: 'Current Odometer',
    cameraPassword: 'Camera Password',
    cameraSerialNo: 'Camera Serial No',
    trailer: 'Trailer',
    hardwareDevice: 'Hardware Device',
    confirmPassword: 'Confirm Password',
    customerName: 'Customer Name',
    driverNamejobName: 'Job Name',
    isValidVin:
      'VIN number must be 17-character long. Numbers and letters only. I, O, or Q are not allowed',
    phone: 'Phone',
    photos: 'Photos',
    carrierName: 'Carrier',
    vehicles: 'Vehicles',
    trailerName: 'Trailers',
    fromLocation: 'From',
    toLocation: 'To',
    mainAddress: 'Main Address',
    terminalAddress: 'Terminal Address',
    shipDocs: 'Ship Docs',
    timeZoneOffsetFromUTC: 'Time Zone Offset From UTC',
    locationOfLogSigning: 'Location Of Log Signing',
    atleast2Chars: 'Please enter at least 2 characters.',
    validNumFormat: 'Please fill in the correct format(844-636-1360).',
    validLicenseNumber: 'License Number should contain alpha numeric characters only.',
    finish: 'Finish',
    breadcrumb: 'Applied Filters :',
    logbookDetails: 'Daily Log for',
    refresh: 'Refresh',
    mph: 'MPH',
    updatedTime: 'Updated Time',
    geocodedLocation: 'Geocoded Location',
    truckId: 'Truck ID',
    popupMessage: 'You will not be able to recover this user!',
    activateMessage: 'You will activate this user!',
    minimumRow: 'Minimum 1 row is required',
    deletePopup: 'Yes, delete it!',
    customPolicyTemplate: 'Click  to view custom policy template',
    acceptPopup: 'Yes, accept it!',
    noData: 'Nothing to display',
    update: 'Update',
    show: 'Show',
    saveChanges: 'Save Changes',
    mainInformation: 'Main Information',
    dataImportSuccess: 'Data has been imported successfully',
    termsValidation: 'Please Accept Terms and Conditions To Continue',
    user: 'User',
    drivingState: 'Driving state',
    total: 'Total',
    fleetManagerDefault: 'Fleet Manager (Default)',
    confirm: 'Confirm',
    passwordValidMessage:
      'Password must have at least an uppercase character, a lowercase character, a digit, a special character, at least 6 characters and at most 15 characters long.',
    filterRequiredMessage: 'Please select atleast one filter',
    upgrade: 'Upgrade',
    reported: 'Reported',
    exportData: 'Export Data',
    paymentName: 'Name on Card',
    timeZone:
      'Your timezone cannot be obtained from the address entered. Please select your timezone below:',
    noDataToPlot: 'No Data to plot',
    atleast4chars: 'Please enter at least 4 characters.',
    noMoreThan9: 'Please enter no more than 9 characters.',
    paymentplaceholder: 'Jane Doe',
    cardDetails: 'Card Details Updated Successfully',
    usdotDetails: 'USDOT Number deleted successfully',
    deleteRegion: 'Region deleted successfully',
    saveRegion: 'Region added successfully',
    updateRegion: 'Region updated successfully',
    terminalAdd: 'Terminal added successfully',
    terminalUpdate: 'Terminal updated successfully',
    deleteTerminal: 'Terminal deleted successfully',
    departmentAdded: 'Department added successfully',
    departmentUpdated: 'Department updated successfully',
    deleteDepartment: 'Department deleted successfully',
    locationAdded: 'Location added successfully',
    locationUpdated: 'Location updated successfully',
    locationDeleted: 'Location deleted successfully',
    partAdded: 'Parts added successfully',
    summary: 'Summary',
    "noDataAvailable": "No data available",
    privacyPolicy: 'Privacy Policy',
    cameraUrlNotFound: "Your account is not registered in Camera portal. Please register in camera portal and try again.",
    serviceManager: "Service Manager",
    yes: 'Yes',
    no: 'No',
    fleetopsUrlNotFound: "This feature is not configured for your account.",
    proceedToAdd: "Proceed To Add",
    proceedToImport: "Proceed To Import",
    pdf: 'PDF',
    regeneratePdf: "Regenerate PDF",
    deleteMessage: "You can't able to restore the state!",
    cameraTypeChangeWarning: 'You are changing camera type to {{camerType}}. This will reset the Camera serial number and password',
    quoteId: 'DOT No.',
    cardDetailsSavedSuccesfully: 'Card Details Saved Successfully',
    max20Char: 'Maxmium character limit exceeds.',
    max60Char: 'Maxmium 60 character allowed',
    onlyletterNumber: 'Only letters and numbers are allowed',
    noofIntialTrucks: 'Number of initial trucks',
    noofIntialCamera: 'Number Of Initial Cameras',
    initialCamera: 'Initial Camera Count'
  },
  avatarMenu: {
    switchToOld: 'Switch to Old View',
    switchToNew: 'Switch to New View',
    messaging: 'Messaging',
    logout: 'Logout'
  },
  bulkDelete: {
    alertSuccess: 'Data has been imported successfully.',
    deleteError: 'An error occurred while deleting',
    imported: 'Data has been imported successfully.',
    deleteVehicle: 'Following {{count}} vehicles are flagged for deletion. Do you want to continue?',
    allSuccess: 'Success',
    deleted: ": The following {{count}} vehicles have been deleted."
  },
  leftMenu: {
    feedback: 'Feedback',
    developerRequests: 'Developer Requests',
    downloadCenter: 'Download Center',
    reports: 'Reports',
    IFTAReport: 'IFTA Report',
    fuelReport: 'Fuel Consumption Report',
    dailyDocuments: 'Daily Documents',
    documents: 'Documents',
    policiesProcedures: 'Policies and Procedures',
    DOTAudit: 'DOT Audit',
    Audit: 'Audit',
    USDOT: 'USDOT No. Management',
    companyData: 'Company Data',
    filters: 'Filters',
    inspections: 'Inspections',
    repairMaintenance: 'Repair and Maintenance',
    accidents: 'Accidents',
    vehicles: 'Vehicles',
    users: 'Users',
    logs: 'Logs',
    csaViolation: 'CSA Violations',
    department: 'Departments',
    camera: "Camera",
    companies: "Companies",
    freight: "Freight",
    dashboard: "Dashboard",
    scoreCard: "Scorecard"
  },
  dashboard: {
    udNotification:
      'You have unassigned Unidentified Driving Time. Please go to the logs tab and assign these logs to the right driver.',
    driverStatus: {
      DRIVING: 'MANAGE USERS - DRIVING',
      ON_DUTY: 'MANAGE USERS - ON DUTY',
      SLEEPER_BERTH: 'MANAGE USERS - SLEEPER',
      OFF_DUTY: 'MANAGE USERS - OFF DUTY'
    },
    notInUseVehicles: {
      pageHeading: {
        INSHOP: 'MANAGE VEHICLES - IN SHOP',
        OUTOFSERVICE: 'MANAGE VEHICLES - OUT OF SERVICE'
      },
      listHeading: {
        INSHOP: 'List Of Vehicles In Shop',
        OUTOFSERVICE: 'List Of Vehicles Out Of Service'
      }
    },
    tabs: {
      driverBehavior: window?.$environment?.SERVICE_PROVIDER === 'riskTheory' ? 'Account Behavior' : 'Driver Behavior',
      vehicleStatus: 'Vehicle Status',
      dailyLogs: {
        heading: 'Daily Logs'
      },
      driverStatus: {
        heading: 'Drivers Status',
        lastKnownLocation: 'Last Known Location',
        unit: 'Unit',
        driveTimeRemaining: 'Drive Time Remaining',
        timeUntilBreak: 'Time Until Break',
        onDutyTimeRemaining: 'On Duty Time Remaining',
        dutyCycleRemaining: 'Duty Cycle Remaining',
        currentStatus: 'Current Status',
        engineSpeed: 'Engine Speed',
        lastUpdated: 'Last Updated',
        driveTime: 'Drive Time',
        onDutyTime: 'On Duty Time',
        eld: 'ELD'
      },
      alerts: {
        enableNotification: 'Enable Notification',
        heading: 'Alerts'
      },
      potentialViolations: {
        heading: 'Potential Violations',
        pageHeading: 'Notifications',
        violationType: 'Violation Type'
      },
      radiusComplianceAlerts: {
        company: 'Policy Holder Name',
        radius: 'Radius',
        type: 'Type',
        radiusExperience: 'Radius Experience',
        description: 'Description',
        date: 'Date',
        overage: 'Overage',
        monthInViolation: 'Month in Violation',
        lossControl: 'Loss Control',
        underwriter: 'Underwriter',
      },
      radius: 'Radius',
      severity: 'Severity',
      csaSnapshot: 'CSA Snapshot',
      recentAccidents: 'Recent Accidents',
      developerRequests: 'Developer Permission Requests',
      overview: 'Overview'
    },
    header: {
      TOTALDRIVERS: 'Total Active Drivers',
      ONDUTY: 'On Duty',
      DRIVING: 'Driving',
      SLEEPER: 'Sleeper',
      OFFDUTY: 'Off Duty',
      TOTALTRUCKS: 'Total Trucks',
      AVAILABLETRUCKS: 'Available Trucks',
      INSHOP: 'In Shop',
      OUTOFSERVICE: 'Out of Service',
      text: 'Company Summary'
    }
  },
  api: {
    errors: {
      noService: 'Service Temporarily Unavailable',
      networkError: 'Internal server error'
    }
  },
  users: {
    listHeading: 'Manage Users',
    addHeading: 'Add User',
    editHeading: 'User details',
    vehicleForMechanic: 'Vehicle for mechanic',
    vehicleForDriver: 'Vehicle for driver',
    enableYardMoves: 'Enable Yard Moves',
    enableNonDOTTrip: 'Enable Non-DOT Trip',
    enablePersonalConveyance: 'Enable Personal Conveyance',
    aGExemption: 'AG Exemption',
    bigDayShortHaul: 'Big Day Short Haul',
    exemptFromUsingELD: 'Exempt from using ELD',
    expirationDate: 'Expiration Date',
    selectFileToUpload: 'Select File to upload',
    resetPasswordHeading: 'Reset Password',
    profileHeading: 'Edit Profile',
    joined: 'Joined',
    All: 'All',
    admins: 'Admins',
    drivers: 'Drivers',
    mechanics: 'Mechanics',
    driverStatusHistory: 'Driver Status History',
    fleetManager: 'Fleet Manager',
    addNewUser: 'Add New User',
    quickFilter: 'Quick filters:',
    serviceManagers: 'Service Managers',
    mechanicListHeading: 'Manage Mechanics',
    searchMechanic: 'Search Mechanic',
    underWriters: 'Underwriters',
    lossControllers: 'Loss Controls',
    claimManagers: 'Claim Adjusters',
    DVIROnly: "DVIR Only",
  },
  logs: {
    manageLogsHeader: 'Manage Logs',
    manageLogsHeaderSigned: 'Signed Logs',
    manageLogsHeaderUnSigned: 'Real-time/Unsigned Logs',
    undefinedDriverHeader: 'Unidentified driver logs',
    addLogHeader: 'Add logs',
    archiveLogsHeader: 'Archive Logs',
    dailyLogExportMessage: 'Export dailylog signed status',
    logBook: 'LogBook',
    unassignedDriveTime: 'Unassigned Drive Time',
    unidentifiedDriverProfile: 'Unidentified Driver Profile',
    logs: 'Logs',
    dateInUtc: 'Date (in UTC)',
    viewDocument: 'VIEW DOCUMENT',
    noViolation: 'No violation data for the day',
    history: 'Daily Log History',
    editHeading: 'Edit Daily Log',
    addHeader: 'User Information',
    codriver: 'Co-Driver Name',
    logLocation: 'Log Signing Location',
    violations: 'Violations',
    status: 'Status',
    stateModifier: 'State Modifier',
    timeFrom: 'Time From',
    timeTo: 'Time To',
    location: 'Location',
    assignedDriver: 'Assigned Driver',
    notes: 'Notes',
    addNewRow: 'Add new row',
    Submit: 'Save',
    UpdateEvents: 'Update events',
    UpdateGraph: 'Update Graph',
    noDataMessage: 'Invalid request. Click here to go back to dashboard',
    backToDashboard: 'GO BACK',
    spireonTitle: 'Daily Logs',
    viewDocument: 'View Documents',
    oildFieldWaiting: 'Oil Field Waiting'
  },
  dca: {
    dcAlert: 'DIAGNOSTIC CODE ALERTS',
    dateFrom: 'Date From',
    dateTo: 'Date To',
    code: 'Code',
    description: 'Description',
    startTime: 'Start Time',
    endTime: 'End Time',
    search: 'Search Code'
  },
  vehicles: {
    wlId: "CRM ID",
    addHeading: 'Add Vehicle',
    editHeading: 'Edit Vehicle',
    fullName: 'Full Name',
    role: 'Role',
    email: 'E-mail',
    empId: 'Emp Id',
    assignedVehicles: 'Assigned Vehicles',
    lastLogin: 'Last Login',
    users: 'Users',
    searchUser: 'Search User',
    cameraType: 'Camera Type',
    importUserTemplate: 'Import User Template',
    exportUser: 'Export Users',
    deactivateUser: 'Yes, deactivate user!',
    activateIt: 'Yes, activate it!',
    listHeading: 'Manage Vehicles',
    searchVehicle: 'Search Vehicle',
    searchVehicleTrailer: 'Search Vehicle / Trailer',
    unitId: 'Unit ID',
    drivers: 'Drivers',
    type: 'Type',
    vin: 'VIN',
    year: 'Year',
    manufacturer: 'Manufacturer',
    manufact: 'Manufact',
    model: 'Model',
    vehicleType: 'Vehicle Type',
    fuelType: 'Fuel Type',
    tabs: {
      listVehicles: 'List of Vehicles',
      listTrailers: 'List of Trailers',
      roadInspection: 'Roadside Inspections'
    },
    exportVehicle: 'Export Vehicles',
    exportTrailer: 'Export Trailers',
    addVehicle: 'Add New Vehicle',
    addTrailer: 'Add New Trailer',
    importVehicleTemplate: 'Import Vehicles Template',
    importTrailerTemplate: 'Import Trailers Template',
    trackVehicle: 'Track Vehicle',
    dateTimeFrom: 'Date/Time From',
    dateTimeTo: 'Date/Time To',
    seatBeltStatus: 'Seat Belt Status',
    importVeh: 'Import Vehicles',
    importTra: 'Import Trailers',
    mechanics: 'Mechanic',
    cameraId: 'Camera ID',
    inspectionForm: 'Inspection Form',
    cameraStatus: 'Status'
  },
  chat: {
    sendMessage: 'Send message',
    selectDriver: 'To: Search...',
    newConvo: 'New Conversation',
    broad: 'Broadcast',
    search: 'Search...',
    type: 'Type'
  },
  trailers: {
    deleteIt: 'Yes, delete it!',
    recoverConfirm: ' You will not be able to recover this trailer!',
    trailerType: 'Trailer Type',
    addHeading: 'Add Trailer',
    editHeading: 'Edit Trailer'
  },
  roadInspection: {
    driverId: 'Driver ID',
    defectFound: 'Defect Found'
  },
  reports: {
    downloadReports: 'Download Reports'
  },
  accidents: {
    heading: 'MANAGE ACCIDENTS',
    witnessStatement: 'Witness Statement',
    accidentReport: 'Accident Report',
    dateAndTime: 'Date and Time',
    viewWitness: 'View Witnesses',
    viewReport: 'View Report',
    viewReceipt: 'View Receipt'
  },
  eld: {
    all: 'All',
    delivery_receipts: 'Delivery Receipt',
    dispatch_records: 'Dispatch Record',
    bills_of_landing: 'Bill of Lading',
    receipts: 'Receipt',
    weigh_tickets: 'Weigh Ticket',
    communication_records: 'Communication record',
    other_documents: 'Other Document'
  },
  documents: {
    listHeading: 'Documents'
  },
  developerRequests: {
    listHeading: 'Developer Permission Requests',
    developerName: 'Developer Name'
  },
  departments: {
    terminalsAndDepartments: 'Terminals and Departments',
    noLocations: 'No location added',
    noDepartments: 'No departments under this terminal',
    addTerminal: 'Add Terminal',
    editTerminal: 'Edit Terminal',
    addRegion: 'Add Region',
    editRegion: 'Edit Region',
    regionName: 'Region Name',
    fleetManagers: 'Fleet Managers',
    dot: 'Dot #',
    addDepartment: 'Add Department',
    editDepartment: 'Edit Department',
    addLocation: 'Add Location',
    editLocation: 'Edit Location',
    deleteTerminal: 'Delete Terminal',
    deleteDepartment: 'Delete Department',
    serviceCenters: "Service Centers",
    filter: 'Filters',
    addFilter: 'Set Filter'
  },
  registration: {
    companyInfoHeading: 'COMPANY INFORMATION',
    userInfoHeading: 'FLEET MANAGER INFORMATION',
    initialTrucks: 'Number of Trucks',
    maintenancePeriod: 'Maintenance Period (Months)',
    maintenanceMileage: 'Maintenance Mileage',
    subscriptionTier: 'Subscription Tier',
    contractLength: 'Contract Length (Years)',
    createAccount: 'Create Account',
    producerCode: 'Producer Code',
    iAgree: 'I agree to the',
    termsOfUse: 'Terms of Use',
    accept: 'Accept',
    totalAmount: 'Total amount: $',
    paymentConfirmation: 'PAYMENT CONFIRMATION',
    noOfTrucks: 'Number of Trucks',
    costperTruck: 'Cost per truck ($)',
    salesTax: 'Sales Tax ($)',
    discount: 'Discount (%) (if any)',
    total: 'Total Amount ($)',
    confirm: 'Confirm',
    iAcceptAll: 'I accept the following terms and conditions.'
  },
  csaViolation: {
    listHeading: 'CSA VIOLATIONS',
    dvirLink: 'DVIR Link',
    dvirStatus: 'DVIR Status',
    roadsideDot: 'Roadside DOT Inspection',
    workOrder: 'Work Order',
    workOrderStatus: 'Work Order Status',
    oos: 'OOS',
    severity: 'Severity'
  },
  iftaMileage: {
    listHeading: 'IFTA Mileage',
    search: 'Search',
    notLessThanZero: 'Meter reading should not be less than zero',
    addressError: `Invalid address.Can't able to find address.`,
    endMeterError: 'end meter should be greater than start meter',
    tooltip: 'Download Truck GPS history',
    hideLabel: 'Hide Jurisdiction with no mileage',
    activeVehicles: 'Active Vehicles',
    deactivateVehicles: 'Deactivated Vehicles',
    odometer: 'Calculate From Odometer',
    updateMiles: 'Update Miles',
    totalMiles: 'Total Miles',
    totalTaxableMiles: 'Total Taxable Miles',
    truck: 'Truck',
    Jurisdiction: 'Jurisdiction',
    generateReport: 'Generate Report',
    fuelUsage: 'Fuel Purchased',
    newState: "New Jurisdiction",
    vehicle: 'Search By Vehicle',
    stateSearch: 'Search By Jurisdiction',
    gpsBegins: 'Beginning GPS Lat & Long',
    gpsEnds: 'Ending GPS Lat & Long',
    startMeter: 'Start Odometer',
    endMeter: 'End Odometer',
    addState: 'Add Jurisdiction',
    editState: 'Edit Jurisdiction',
    startCity: "Start City",
    endCity: "End City",
    taxable: "Taxable"
  },
  fuelConsumption: {
    pageHeading: 'FUEL CONSUMPTION REPORT',
    search: 'Search Report by Vehicle',
    currentDrivers: 'Current Drivers',
    location: 'Latest Fueling Location',
    Jurisdiction: 'Jurisdiction',
    JurisdictionLocation: 'Jurisdiction/Location',
    miles: 'Miles per Gallon',
    history: 'Refills History',
    unknown: 'Unknown',
  },
  refillsHistory: {
    pageHeading: 'History of Refills',
    odometer: 'Odometer',
    gallons: 'Gallons',
    fuelAmount: 'Fuel Amount (Gallons)',
    Jurisdiction: 'Jurisdiction',
    cost: 'Total Cost'
  },
  usDotManagement: {
    pageHeading: 'USDOT NUMBER MANAGEMENT PAGE',
    search: 'Search USDOT No',
    usDotNo: 'USDot No.',
    carrierName: 'Name of Carrier',
    mainAddress: 'Main Office Address',
    zip: 'Zip',
    addDot: 'Add New DOT',
    editDot: 'Edit DOT',
    message: 'Message',
    send: 'Send'
  },
  inspections: {
    listHeading: 'Manage Inspections',
    listHeading1: 'Inspections',
    user: 'Search By User',
    vehicle: 'Search By Vehicle',
    inspectionType: 'Inspection Type',
    inspectionReport: 'Inspection Report',
    advancedDvir: 'Advanced DVIR',
    bulkDownloadReport: 'Bulk Download Report',
    advancedInput: 'Section',
    dvirError: 'Configuration type or section name not entered',
    viewReport: 'View Report',
    customDvir: 'Custom DVIR',
    searchBycompany: 'Search By Company',
    searchByMechanic: 'Search By Mechanic',
    hasDefects: "Has Defects?",
    assetsCount: "Assets Count",
    assetsType: "Assets Type",
    assetsName: "Asset Name",
    unitType: "Unit Type",
  },
  workOrders: {
    search: 'Search Work Order',
    pageHeading: 'MANAGE WORK ORDERS',
    workOrderNo: 'Work Order #',
    reportedDate: 'Reported Date',
    scheduledDate: 'Scheduled Date',
    dueDate: 'Due Date',
    repairCost: 'Repair Cost($)',
    inspectionReport: 'Inspection Reports',
    serviceType: 'Service Type',
    open: 'Open WorkOrders',
    closed: 'Closed WorkOrders',
    addWorkOrders: 'Add WorkOrder',
    manageParts: 'Manage Parts',
    driver: 'Driver',
    unitType: 'Unit Type',
    addHeading: 'WORK ORDER DETAILS',
    status: 'Status',
    inspectionDetails: 'INSPECTION DETAILS',
    concern: 'Concern',
    cause: 'Cause',
    fuel: 'Fuel Quantity',
    engineOil: 'Engine Oil Quantity',
    hydraulicOil: 'Hydraulic Oil Quantity',
    windShieldFluid: 'Windshield washer Quantity',
    correction: 'Correction',
    parts: 'Parts',
    laborCost: 'Labor Cost ($)',
    partsCost: 'Parts Cost ($)',
    totalCost: 'Total Cost ($)',
    mechanic: 'Mechanic',
    scheduled: 'Scheduled',
    notes: 'Notes',
    receipts: 'RECEIPTS',
    viewReport: 'View Report',
    viewReceipt: 'View Receipt',
    viewPicture: 'View Picture',
    noReport: 'No Report',
    viewPhotos: 'View Photos',
    viewInspectionreport: 'View Inspection Report',
    company: "Company",
    vehicle: "Vehicle",
    assignToServiceCeenter: "Assign to another Service Center",
    assignWorkOrder: "Assign Service Request",
    serviceCenter: "Service Center",
    defectDetails: "Defect Details",
    unableToChangeStatus: 'Service Request assigned to a service center. Unable to make this change!',
    unavailable: "-",
    workOrderEditSuccess: 'Work Order Has Been Updated.',
    serviceRequestEditSuccess: 'Service Repair Request Has Been Updated.',
    manageMechanics: 'Manage Mechanics',
    dvirLocation: 'DVIR Location'

  },
  parts: {
    pageHeading: 'Manage Parts',
    partName: 'Part',
    partsInventory: '# in Inventory',
    cost: 'Parts Cost ($)',
    addPart: 'Add Part',
    partTitle: 'Parts',
    costError: 'Please enter a value greater than or equal to 0.'
  },
  companyData: {
    pageHeading: 'Company Data',
    editPageHeading: 'Edit Company',
    addPageHeading: 'Add Company',
    companyName: 'Company Name',
    maintainancePeriod: 'Maintenance Period',
    maintainancePeriodMonth: 'Maintenance Period (Months)',
    maintainanceMileage: 'Maintenance Mileage',
    contactPerson: 'Contact Person',
    noOfTrucks: 'Number Of Trucks',
    noOfCameras: 'Initial Camera Count',
    homeTimeZone: 'Home Time Zone',
    otherLocationContactPerson: 'Other Location Contact Person',
    otherLocationAddress: 'Other Location Address',
    otherLocationPhoneNumber: 'Other Location Phone Number',
    website: 'Website',
    exceptions: 'Exceptions',
    defaultCycleRule: 'Default Cycle Rule',
    vehicleType: 'Vehicle Type',
    updateCardDetails: 'Update Card Details',
    paymentInformation: 'Payment Information',
    cancelSubscription: 'Cancel Subscription',
    updateCreditCardDetails: 'Update Credit Card Details',
    contractExpirationDate: 'Contract Expiration Date',
    currentSubscriptionCost: 'Current Subscription Cost',
    skipcompanyCheckbox: 'Skip Vehicle Filter Search',
    enablecompanyCheckbox: 'Enable Oil Field Waiting',
    advanceSettings: "Advance Settings",
    insuranceSettings: "Insurance Settings",
    radiusCompliance: "Radius Compliance",
    underwriter: "Underwriter",
    lossControl: "Loss Control",
    garagingAddress: "Garaging Address:",
    DVIR: "DVIR",
    Ifta: "IFTA",
    fleetManagerInformation: "Fleet Manager Information",
    invalidGaragingAddress: 'Unable to generate coordinates from the garaging address provided. Please provide more specific address',
    expiryDate: "Insurance Expiration Date",
    assetsType: "Assets Type",
    billingInformation:"Billing Information",
    noOfActiveTrucks: 'Number of active trucks'
  },
  paymentInformation: {
    listHeading: 'Payment Details',
    contractExpiry: 'CONTRACT EXPIRATION DATE',
    companyInfo: 'Company Info for',
    contractHistory: 'Contract History',
    startDate: 'Contract Start Date',
    contractLength: 'Contract Length',
    nextBilling: 'Next Billing Date',
    paymentHistory: 'Payment History for Test Tracks Company',
    createdDate: 'Created Date',
    paidDate: 'Paid Date',
    unitCount: 'Unit Count',
    cost: 'Cost Per Unit',
    receipt: 'Payment Receipt',
    start: 'Start Date',
    end: 'End Date',
    noOfDays: 'No.Of Days',
    status: 'Payment Status',
    company: 'Company Name',
    paymentId:'Payment Id',
    companyInfoHeading:"Company Info",
    paymentHistoryHeading:'Payment History',
  },
  suspended: {
    listHeading: 'Subscription',
    buttonText: 'Pay With Card',
    title: 'Payment Details',
    description: 'You have active contract from',
    to: 'till',
    payButtonText: 'ADD CARD DETAILS ',
    'expiredContract': 'Your contract has expired.',
    saveButtonText: 'Save',
  },
  policies: {
    heading: 'Policies and Procedures',
    mainInfo: 'Main Information',
    selectPolicies: 'Select Policies',
    customPolicies: 'Custom Policies',
    companyLogo: 'Company Logo',
    chooseImage: 'Please choose .png or .jpg file',
    DRESS_CODE: 'Dress Code',
    INSPECTION: 'Inspection',
    PSP_USAGE: 'PSP Usage',
    LOSS_CONTROL_MANUAL_POLICY: 'Loss Control Manual',
    DRIVER_SELECTION_MVR: 'Driver Selection and MVR',
    DRIVING_TRAINING: 'Driving Training',
    ACCIDENT_INVESTIGATION: 'Accident Investigation',
    DISTRACTION_FREE_DRIVING: 'Distraction Free Driving',
    MIN_DRIVER_AGE: 'Minimum driver age allowed',
    MIN_YEARS_ON_SIMILAR_EQUIPMENT: 'Minimum years on similar equipment',
    NUMBER_OF_ACCIDENTS_ALLOWED: 'Maximum number of accidents',
    NUMBER_OF_CITATIONS_ALLOWED: 'Maximum allowable number of citations',
    MIN_HOURS: 'Hours of Training to be Completed Annually',
    TRAINING_DAYS: 'Training Days',
    SAFETY_PERSON_FULL_NAME: 'Full Name of Safety Manager',
    ALLOW_USING_PHONES: 'Allow Bluetooth Usage',
    CUSTOM_POLICY: 'Custom Policy',
  },
  potentialViolations: {
    heading: 'Potential Violations',
    pageHeading: 'Manage Violations',
    violationType: 'Violation Type'
  },
  dailyDocuments: {
    receipt: 'Receipt',
    users: 'Users',
    documentType: 'Document Type',
    cusName: 'Customer Name/Job Name',
    uploadNewDoc: 'Upload New Document'
  },
  popup: {
    success: 'Success'
  },
  reportPage: {
    dutyStatus: 'Duty Status',
    driverPerf: 'Driver Performance Report',
    editedData: 'Edited Data Report',
    rejectedEdits: 'Rejected Edits',
    drivingEvents: 'Driving Event Report',
    eldDisconn: 'ELD Disconnect Report',
    odoJump: 'Odometer Jump Report',
    malFunc: 'Malfunction Report',
    unassigned: 'Unassigned Driving Report',
    violation: 'Violation Report',
    pendingDriverDVIR: 'Pending Driver DVIR',
    pastDueServiceRequests: 'Past Due Service Requests',
    noOfRequestsPerDay: 'No Of Requests Per Day',
    pendingMechanic: 'Pending Mechanic',
    hos: 'HOS Violations Details',
    billOfLading: 'Bill Of lading Report',
    DailyHocDriverViolation: "HOS Violations Report",
    dailyUnsignedLogReport: "Unsigned Log Report",
    DailyFailedInspectionReport: "Failed Inspection Report",
    DailyUnidentifiedDrivingReport: "Unidentified driving report",
    MonthlyHOSDriverViolationsReport: "Monthly HOS Driver Violations Report",
    MonthlyUnsignedLogReport: "Monthly Unsigned Log report",
    borderStates: "Document Time Report",
    bayIndustriesPayroll: "Payroll Report",
    allDutyTimeTracker: "All Duty time tracker Report",
    dtc: 'DTC Report'
  },
  graphTitles: {
    hardAcceleration: 'Hard Acceleration',
    hardDeceleration: 'Hard Deceleration',
    speeding: 'Speeding',
    hoursExceeding: 'Driving Hours Exceeded',
    fuelEconomy: 'Fuel Economy',
    nonBuckledSeatbelt: 'Non Buckled Seat Belt Warning',
    hardTurn: 'Hard Turn',
  },
  goToInspections: 'to go back to Inspections',
  companies: {
    listHeading: 'Manage Companies',
    companyName: "Company Name",
    dot: "Dot #",
    activeTrucks: "Active Trucks",
    email: "Email",
    phone: "Phone",
    severity: "Severity",
    fleetScore: 'Fleet Score (out of 100)',
    fleetManager: "Fleet Manager",
    searchCompanies: "Search Companies",
    activeVehicles: "Active Vehicles",
    lossControl: "Loss Control",
    xDate: "X-Date",
    alerts: "Alerts",
    comapanyNumber: "Company #",
    policyHolderNumber: 'Policy Holder #',
    paymentStatus:"Payment Status",
  },
  defectDetails: {
    area: "Area",
    part: "Part",
    defectDetails: "Defect Details",
    commentsByMechanic: "Comments by Mechanic",
    fixStatus: "Fix Status",
    comments: "Comments by Driver",
    status: "Verification Status",
    viewPhoto: "View Photo",
  },
  scoreCard: {
    heading: ':name Scorecard',
    fleet: 'Fleet',
    policyHolderManagementScore: 'Policy Holder Management Score',
    policyHolderManagement: 'Policy Holder Management',
    address: 'Address',
    dotNumber: 'DOT Number',
    xDate: 'X-Date',
    date: 'Report Date',
    fleetManagement: 'Fleet Management',
    operatingTerritory: 'Operating Territory',
    radiusStats: 'Daily Radius',
    fleetManagementScore: 'Fleet Management Score',
    vehicleInspectionScore: 'Vehicle Inspection Score',
    consistentEquipment: 'Consistent Equipment',
    vehicleComposition: 'Vehicle Composition',
    driverTurnOver: 'Driver Turn Over',
    driverTenure: 'Driver Tenure (Years)',
    quickStats: 'Quick stats',
    radiusCompliance: "Radius Compliance",
    radiusTable: {
      driverName: 'Driver Name',
      vehicle: 'Vehicle',
      radius: 'Radius',
      radiusExperience: 'Radius Experience',
      overage: 'Overage',
      date: 'Date of Violation'
    }
  },
  "selectWithDot": "Select...",
  policyHolder: "Policy Holder",
  newScoreCard: {
    heading: 'Scorecard Overview',
    selectPeriod: 'Select Period',
    unpoweredDays: 'Unpowered Days',
    poweredDays: 'Powered Days',
    driverBehavior: 'Driver Behaviour',
    fleetScore: 'Fleet Score',
    radiusViolation: 'Radius Violation',
    distractedDriving: 'Distracted Driving',
    speeding: 'Speeding',
    contact: 'Contact',
    totalVehicle: 'Total Vehicles',
    accidentTooltipTitle: "Accident is graded based on the following events :",
    speedingTooltipTitle: "Speeding is graded based on the following events : ",
    distractedTooltipTitle: "Distracted Driving is graded based on the following events : ",
  },
  scorecard: {
    "driverBehavior": "Driver Behavior",
    "unpoweredDays": "Unpowered Days",
    "unpoweredDaysDescription": "Based on the number of days trucks were powered. Higher powered days result in a higher score.<br/><br/><i>Method: 0.2 * Powered Days Score</i>",
    "driverBehaviorDescription": "Calculated based on the number of driving events. The score represents safe driving behavior, with fewer events leading to a higher score.<br/><br/><i>Method: 0.6 * Driver Behavior Score</i>",
    "radiusViolations": "Radius Violations",
    "radiusViolationsDesc": "Measures the days trucks violated the predefined radius. Fewer violation days result in a higher score.<br/><br/><i>Method: 0.2 * Radius Violation Score</i>",
    "total": "Total",
    "vehicle": "Vehicles",
    driverBehaviorDesc: "Calculated based on the number of driving events across all trucks.<br /><br /><i>Method: 100 - (Sum of Events of all trucks / (Total Working Days * Total Trucks)) * 100.</i><br /><br />The pie chart shows the number of trucks grouped by their individual performance percentages, indicating how many trucks fall within each defined range.",
    actualScore: "Actual Score",
    distractedDriving: "Distracted Driving",
    accident: "Accident",
    speeding: "Speeding",
    moreInfo: "More Info",
    unpoweredDaysGraphDesc: "Calculated based on the number of days trucks were powered.<br /><br /><i>Method: Sum of each truck's Powered Days / (Total Working Days * Total Trucks) * 100.</i><br /><br />The pie chart shows the number of trucks grouped by their individual performance percentages, indicating how many trucks fall within each defined range.",
    unpoweredDaysTotalDesc: "The cumulative number of unpowered days across all trucks in the fleet",
    radiusViolationGraphDesc: "Calculated based on the number of days trucks violated the predefined radius.<br /><br /><i>Method: 100 - (Sum of each truck's Violation Days / (Total Working Days * Total Trucks)) * 100.</i><br /><br />The pie chart shows the number of trucks grouped by their individual performance percentages, indicating how many trucks fall within each defined range.",
    radiusViolationTotalDesc: "The cumulative number of radius violation days across all trucks in the fleet",
    noDataText: "No Data Available !",
    excellent: "Excellent",
    good: "Good",
    average: "Average",
    belowAverage: "Below Average",
  },
  banner: {
    tellUs: 'Tell us more about your fleet!',
    haveCameraQ: 'Do you have Cameras?',
    haveInsuranceQ: 'Who is your Insurance Provider?',
    yes: 'Yes',
    no: 'No',
    cameraProviderQ: 'Who is your Camera Provider?',
    policyRenewQ: 'When does your Policy Renew?',
    submit: 'Submit',
    later: 'Cancel',
    thankYou: 'Thank you for your response!',
    thankYouInfo: 'This window will disappear in {{ count }} seconds',
    close: 'OK',
    email: 'Email Address',
    phone: 'Phone Number',
    emailValidation: 'Email must be a valid',
    cameraProviderPlaceholder: 'Enter your camera provider',
    insuranceProviderPlaceholder: 'Enter your Inusurance provider',
    emailPlaceholder: 'example@domain.com',
    phoneNumberPlaceHolder: '000-000-0000',
  }
};
