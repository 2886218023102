import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { getImagePath, getStyle } from '../../../utils/utils';
const isWebfleet = window.$environment.SERVICE_PROVIDER === 'webfleet'
const isEs = () => localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'

const ParentWrapper = styled.div`
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-flow: nowrap;
`;
const Wrapper = styled.div`
${({ theme, drawerwidth, widthnounit }) => `
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%
  .footerClass {
    position: fixed;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 15px;
    bottom: 0;
    height: 50px;
    color: #fff;
    background-color: ${theme.colors.HEADER_COLOR};
    span {
      font-size: 17px;
      font-weight: bold;
      margin-right: 10px;
    }
    b {
      margin: 0 6px;
    }
  }
`}`;
const Main = styled.main`
  ${({ theme, drawerwidth, widthnounit }) => `
  // background-color: ${theme.colors.BLACK};
  width: 100%;
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: row;
  background-position: center;
  overflow: hidden;
  position: relative;
  z-index: 0;
 `}
`;
const LeftDrawer = styled(Box)`
  ${({ theme, drawerwidth, widthnounit, newUI, collapse }) => `
  background-color: ${theme.colors.BACKGROUND_MENU}
  background-color: #455560;
  ${collapse && `
  width: ${isEs() ? '415px' : '300px'};
    position: absolute;
    z-index: 2001;
    height: 100%;
    overflow-y: auto;
  `}
  .MuiIconButton-root:hover {
    background-color: ${getStyle(
      '',
      null,
      '#bdd731'
    )};
  }
  .MuiListItem-button:hover{
    background-color: '#bdd731';
  }
  .drawerIcon {
    position: absolute;
    top: 2.9%;
    z-index: 1000;
    left: ${widthnounit - 1}%;
    color: ${getStyle(
      '',
      (theme.palette.secondary || {}).main,
      theme.colors.SECONDARY_MAIN
    )};
    background-color: ${getStyle(
      '',
      (theme.palette.primary || {}).main,
      theme.colors.PRIMARY_MAIN
    )};
    border: solid 3px
      ${getStyle(
        '',
        (theme.palette.primary || {}).border,
        theme.colors.PRIMARY_BORDER
      )};
    padding: 0px;
    font-size: 1.2rem;
    ${theme.breakpoints.down('xl')}: {
      top: 2.9% !important;
    }
    .MuiSvgIcon-root {
      width: 0.7em;
      height: 0.7em;
    }
  }
  .MuiBox-root {
    width: 100%;
    position:relative;
  }
  .header-right{
    flex: 0 0 55%;
  display: flex;
  justify-content: flex-end;
  }
  #logoContainer {
    text-align: center;
    text-align: center;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.HEADER_COLOR}
    &:after{
      content: "";
      position: absolute;
      left: 48px;
      top: 25%;
      height: 50%;
      border: 0.1px solid #f1f3f3;
    }
    ${!newUI && `
    img {
      @media (min-width: ${theme.breakpoints.values.xsm}px) and (max-width: ${
    theme.breakpoints.values.sm
  }px) {
        width: 53px;
        height: 53px;
      }
      @media (min-width: ${theme.breakpoints.values.xs}px) and (max-width: ${
    theme.breakpoints.values.xsm
  }px) {
        width: 45px;
        height: 45px;
      }
      @media (min-width: ${theme.breakpoints.values.md}px) and (max-width: ${
    theme.breakpoints.values.lg
  }px) {
        width: 45px;
        height: 45px;
      }

      @media (min-width: ${theme.breakpoints.values.sm}px) and (max-width: ${
    theme.breakpoints.values.md - 1
  }px) and (orientation: landscape) {
        width: 85px;
        height: 80px;
      }
      @media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${
    theme.breakpoints.values.xl - 1
  }px) {
        width: 95px;
        height: 80px;
      }
    }
    `}
  }
  `}
`;
const Content = styled(Box)`
  width:100%;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const FooterWrapper = styled(Box)`
  ${({ drawerwidth }) => `

  margin-left: ${drawerwidth};
  width: 100%;
  `}
`;
const ClutchHeader = styled.div`
  ${({ theme, newUI, isServiceManager }) => `
display: flex;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
background-color: ${theme.colors.NEW_THEME ? theme.colors.HEADER_BG :theme.colors.HEADER_COLOR};
padding: ${newUI? `0% 2% 0% 0`: window.$environment.SERVICE_PROVIDER == 'patriot' ? '0.3% 2%' : `1% 2%`};
height: ${newUI ? `60px` : window.$environment.SERVICE_PROVIDER == 'patriot' ? '90px' : `80px`};
justify-content: space-between;
// ${newUI && `max-height: 6vh;`}
flex-wrap: wrap;
.header-right{
  flex: 0 0 55%;
display: flex;
justify-content: flex-end;
}
.logo-wrapper {
  flex: 0 0 25%;
  display: flex;
  justify-content: start;
  position: relative;
}
.logoBox{
  display: flex;
  align-items: center;
  height: 100%;
}
#logoContainer{
  align-self: center;
    display: flex;
    justify-content: center;
    padding: 0px 19px;
    margin-right: 4%;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    img{
      width: 17px;
      height: 17px;
    }
    &:after{
      content: "";
      position: absolute;
      left: 58px;
      top: 25%;
      height: 50%;
      border: 0.1px solid #f1f3f3;
    }
}
.clutchLogo {
  cursor:pointer;
  max-height: 100%;
  ${window.$environment.SERVICE_PROVIDER == 'eldx' ?'height: 90%' :''}
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    // flex: 0 0 71%;
  }
  .clutchLogo-min {
    flex: 0 0 38%;
  }
  @media (max-width: ${theme.breakpoints.values.smd}px) {
    background:  url(${getImagePath(
      'icons/mobileInnerlogo.png'
    )}) no-repeat left;
    background-size: 26px;
    flex: 0 0 10%;
  }
}

.logedIcon{
  @media (max-width: ${theme.breakpoints.values.md}px) {
   width:50%;
  }
}
.userprofile {
  color: ${theme.colors.NEW_THEME ? theme.colors.HEADER_COUNT_TEXT :theme.colors.PRIMARY_CONTRAST};
  font-size: 13px;
  font-family: ${theme.palette.fontFamily};
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  flex: ${!newUI? "0 0 28%": "0 0 28%" };
  align-items: center;
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 6%
  }
  justify-content: flex-end;
  .userdetails {
    border-radius: 50%;
    margin-right: 5%;
    ${!newUI && `width: 40px`}
    @media (max-width: ${theme.breakpoints.values.md}px) {
      display: none;
      flex: 0 0 8%
    }
    cursor:pointer;
  }
  .profileIconLogo {
    cursor: pointer;
    width: 40px;
    height: 40px;
    justify-content: space-around;
    display: flex;
    align-items: center;
    border-radius: 50%;
    background: ${theme.colors.USER_LOGO_BACKGROUND};
    color: ${theme.colors.USER_LOGO_COLOR};
    text-transform: uppercase;
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
  }
  .profileWrapper{
    margin-right: 5%;
    position: relative;
  }
  .nameSection {
    white-space: nowrap;
    // min-width: 80px;
    // max-width: 150px;
    width: 110px;
    display: block;
    word-wrap: break-word;
    white-space: normal;
    position: relative;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
    padding-left: 8px;
    ${newUI && `
    font-weight: bold;
  `}

    @media (max-width: ${theme.breakpoints.values.mlg}px) {
      display: none;
    }
    cursor:pointer;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   background: #2C3249;
    //   right: 1px;
    //   top: 0px;
    //   height: 29px;
    //   border: 0.1px solid #2C3249;
    // }
  }

  .labelLogout {
    color: #1ea7db;
    background:  url(${getImagePath('icons/logoutIcon.png')}) no-repeat center;
    width: 50px;
    cursor: pointer;
    background-color: ${theme.colors.LOGOUT_BUTTON};
    height: 40px;
    border-radius: 3px;
    align-self: center;
    ${newUI && `
    border-radius: 50%;
    flex: 0 0 42px;
    height: 42px;
  `}
    &:hover {
      opacity: 0.5;
      border: 1px solid #fff;
    }
  }

}
.right-side-wrapper{
  display: flex;
  justify-content: flex-end;
}
.countWrapper {
  flex: 0 0 ${isServiceManager? `27%`: '40%'};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: ${theme.breakpoints.values.lg}px) {
    flex: 0 0 80%;
  }
  @media (max-width: ${theme.breakpoints.values.md}px) {
    flex: 0 0 40%;
  }
}
.countWrapper-min {
  flex: 0 0 33%;
}
.countItem: nth-child(n+3) {
  flex: 0 0 12%;
  @media (max-width: ${theme.breakpoints.values.smd}px) {
  display: none;
  }
}

.display-none{
  visbil
}
.countItem {
  ${newUI? `flex: 0 0 30%;`: `flex: 0 0 14%;`}
  position: relative;
  display: flex;
  padding-left: 10px;
  @media (max-width: ${theme.breakpoints.values.md}px) {
  padding-left: 5px;
  ${newUI? `justify-content: center`: ''}
  }
  &:after {
    content: "";
    position: absolute;
    background: #2C3249;
    right: 0;
    top: 6px;
    height: 29px;
    border: 0.1px solid ${theme.colors.PAGINATION_BORDER};
  }
  .count  {
    color: ${theme.colors.HEADER_COUNT_TEXT};
    font-size: 30px;
    font-weight: 600;
    cursor:pointer;
    text-decoration: none;
  }
  .no-link{
    cursor: default;
    color: ${theme.colors.GREY} !important;

  }
  img {
    padding-right: 10px;
    @media (max-width: ${theme.breakpoints.values.md}px) {
      width: 50%;
      padding-right: 5px;
      display:none;
    }
    @media (max-width: ${theme.breakpoints.values.sm}px) {
      display: none;
    }
  }
  .countText {
    color: ${theme.colors.HEADER_LIST_TEXT};
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    width: ${isWebfleet && isEs() ? '83px' : '70px'};
    align-self: center;
    padding-left: 8px;
  }
}
`}
`;

const FloatContainer = styled.div`
  position: fixed;
  bottom: 2%;
  right: 2%;
  cursor: pointer;
  z-index: 1000;
  img {
    width: 30px;
    height: 32px;
  }
`;
const OptionContainer = styled(Box)`
  ${({ theme }) => `
    padding: 10px 12px;
    width: 165px;
    position: absolute;
    z-index: 20;
    top: 42px;
    background-color: ${theme.colors.PRIMARY_CONTRAST};
    border: 2px solid ${theme.colors. PRIMARY_BORDER};
    right: 0;
    .MuiMenuItem-gutters {
      padding-left: 0;
      .menuItem span {
        padding-left: 9px;
        margin-top: -3px;
      }
    }
  .MuiMenuItem-root {
    font-size: 16px;
  }

  .MuiListItemIcon-root {
    align-items: baseline;
    color: ${getStyle(
      '',
      (theme.palette.HEADER_COLOR || {}).contrastText,
      theme.colors.HEADER_COLOR
    )}};
    font-size: 25px;
  }
  `}
`;
const Styled = {
  Wrapper,
  Main,
  LeftDrawer,
  Content,
  FooterWrapper,
  ClutchHeader,
  FloatContainer,
  ParentWrapper,
  OptionContainer
};

export default Styled;
