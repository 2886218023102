let eldX_reseller = window.location.pathname.split("/")[1] || sessionStorage.getItem("eldX_reseller")
export default {
  'preprod.gorillasafety.org': {
    APP_LAYOUT: 'NewUi',
    BASE_URL: 'https://preprod.gorillasafety.org',
    BE_BASE_URL: 'https://preprod.gorillasafety.org/api',
    BASE_CAMERA_URL: 'https://preprod.gorillasafety.org/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'gs',
    SWITCH_APP_LAYOUT: 'OldUi',
    GMAP_KEY: 'AIzaSyBnjFoOyqXkkS_y-aXp7GFWsnKfo8Z_Hng',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'clutch.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://clutch.gorillasafety.org',
    BE_BASE_URL: 'https://clutch.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'clutch',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'trackit.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://trackit.gorillasafety.org',
    BE_BASE_URL: 'https://trackit.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackit',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'alert@trakitgps.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'eldmandate.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://eldmandate.gorillasafety.org',
    BE_BASE_URL: 'https://eldmandate.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'em',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    GMAP_KEY: 'AIzaSyCC8c21znoJly89haW74TPwR6QoliU7vzk',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'spireoneld.gorillasafety.org': {
    APP_LAYOUT: 'SpireonNew',
    BASE_URL: 'https://spireoneld.gorillasafety.org',
    BE_BASE_URL: 'https://spireoneld.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'spireon',
    SWITCH_APP_LAYOUT: 'SpireonOld',
    GMAP_KEY: 'AIzaSyDKNSVf3qP5_Ck_SQ_BmzHTrocqO2XhUXA',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'truckerpath.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://truckerpath.gorillasafety.org',
    BE_BASE_URL: 'https://truckerpath.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'tp',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    GMAP_KEY: 'AIzaSyDiinAX9MMRaMZL_SdXt_GAa7qObWKsB7c',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    ZENDESK_KEY: '7fefde19-a018-44b2-b68f-f3b12af5835f',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'trackoneld.gorillasafety.org': {
    APP_LAYOUT: 'OldUi',
    BASE_URL: 'https://trackoneld.gorillasafety.org/',
    BE_BASE_URL: 'https://trackoneld.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackon',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    REG_LINK: 'https://preprod.gorillasafety.org/registration/step1',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'risktheory.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://risktheory.gorillasafety.org',
    BE_BASE_URL: 'https://risktheory.gorillasafety.org/api',
    BASE_CAMERA_URL: 'https://risktheory.gorillasafety.org/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'riskTheory',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'eldx.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://eldx.gorillasafety.org',
    BE_BASE_URL: `https://eldx.gorillasafety.org/api`,
    WHITE_THEME: true,
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'eldx',
    RESELLER: '/' + eldX_reseller,
    S3_URL: `https://eldx-reseller-staging.s3.amazonaws.com/${eldX_reseller}`,
    GMAP_KEY: 'AIzaSyBnjFoOyqXkkS_y-aXp7GFWsnKfo8Z_Hng',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'simplex.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://simplex.gorillasafety.org',
    BE_BASE_URL: 'https://simplex.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'simplex',
    WHITE_THEME: true,
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'azuga.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://azuga.gorillasafety.org',
    BE_BASE_URL: 'https://azuga.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'azuga',
    WHITE_THEME: true,
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'allwaystrack.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://allwaystrack.gorillasafety.org',
    BE_BASE_URL: 'https://allwaystrack.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'at',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'trackit.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://trackit.gorillasafety.org',
    BE_BASE_URL: 'https://trackit.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'trackit',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'alert@trakitgps.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'fleettrack-preprod.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://fleettrack-preprod.gorillasafety.org',
    BE_BASE_URL: 'https://fleettrack-preprod.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    WHITE_THEME: true,
    SERVICE_PROVIDER: 'ft',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@lbtechnology.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'cyntrx.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://cyntrx.gorillasafety.org',
    BE_BASE_URL: 'https://cyntrx.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'cyntrx',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'cyntrxdvir.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://cyntrxdvir.gorillasafety.org',
    BE_BASE_URL: 'https://cyntrxdvir.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'edvir',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'webfleet.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://webfleet.gorillasafety.org',
    BE_BASE_URL: 'https://webfleet.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'webfleet',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    clutchNewUI: true,
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'hcss-preprod.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://hcss-preprod.gorillasafety.org',
    BE_BASE_URL: 'https://hcss-preprod.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'hcss',
    GMAP_KEY: 'AIzaSyAu6E62k_DvRW1KCILrCkZynj89818Yhgw',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@hcss.com',
    FE_GIG_URL: 'https://stg.gorillainsurancegroup.com/',
  },
  'nauto-preprod.gorillasafety.org': {
    APP_LAYOUT: 'Clutch',
    BASE_URL: 'https://nauto-preprod.gorillasafety.org',
    BE_BASE_URL: 'https://nauto-preprod.gorillasafety.org/api',
    COOKIE_EXPIRY: 1,
    SERVICE_PROVIDER: 'nauto',
    GMAP_KEY: 'AIzaSyApgt0EmHi4Yyo6s3tsDGOJ-whRMnUztDI',
    BRAINTREE_KEY: 'sandbox_8dbwmt73_rfw4k3hkvqppvc68',
    SITE_KEY: '6LegjNcZAAAAAIG5P0IA3CTpvOvC5h13Zeg6JW_p',
    SUPPORT_EMAIL: 'support@nauto.com',
    BASE_CAMERA_URL: 'https://nauto-preprod.gorillasafety.org/api/waylens-client/',
    CAMERA_TOKEN: '9cf11b4ccf46a304a871ad80ec360a4fde57c12820953b61732f287f10e833e9',
  }
};