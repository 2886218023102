import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import moment from 'moment';
import { imageUrls, pageUrls } from '../../../config/constants/keys';
import { BACKEND_DATE_FORMAT } from '../../../config/constants/static';
import { getImagePath, hasValue, isInsuracePortalUser } from '../../../utils/utils';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Form = lazy(() => import(`../../../components/UI/Form/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const TrailerForm = ({
  apiStatus,
  saveTrailerData,
  trailer,
  history,
  saveDocument,
  deleteDocument,
  callback,
  fetchTerminals,
  fetchDepartments,
  fetchSubDepartments,
  regions,
  type,
  isValidVin,
  logBook,
  fleetCompanies,
  getRegions,
  recallUpdate,
  terminals,
  departments,
  subDepartments,
  disabledCriteria,
  Styled,
  receipt,
  downloadDocument
}) => {
  const [forms, setForms] = useState([]);
  useEffect(() => {
    let url = receipt ? receipt : '';
    document.getElementById(`downloadDocument`).src = url;
  }, [receipt]);
  useEffect(() => {
    if (layout !== 'Clutch') {
      setValidationlist({
        ...validationlist,
        type: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
        name: Yup.string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .max(10, i18n.t('common.validName')),
        // vin: Yup.string()
        //   .matches(/[^\WIOQioq]+$/, i18n.t('common.isValidVin'))
        //   .test(
        //     'len',
        //     i18n.t('common.isValidVin'),
        //     (val) => val?.length === 17
        //   ),
        esn: Yup.string().when(["trackingDevice"], {
          is: (value) => (!!value?.value),
          then: Yup.string().required('common.fieldRequiredMessage'),
          otherwise: Yup.string()
        }),
        make: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
        model: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
        next_maintenance_date: Yup.string().required(
          i18n.t('common.fieldRequiredMessage')
        )
      });
    } else {
      setValidationlist({
        ...validationlist,
        type: Yup.string().required(i18n.t('common.fieldRequiredMessage')),
        name: Yup.string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .max(10, i18n.t('common.validName')),
          esn: Yup.string().when(["trackingDevice"], {
            is: (value) => (!!value?.value),
            then: Yup.string().required('common.fieldRequiredMessage'),
            otherwise: Yup.string()
          }),
        // vin: window.$environment?.SERVICE_PROVIDER === 'clutch' ? Yup.string()
        //   .matches(/[^\WIOQioq]+$/, i18n.t('common.isValidVin'))
        //   .test(
        //     'len',
        //     i18n.t('common.isValidVin'),
        //     (val) => val?.length === 17
        //   ) : window.$environment?.SERVICE_PROVIDER === 'webfleet' ? Yup.string()
        //   .test(
        //     'len',
        //     i18n.t('common.isValidVin'),
        //     (val) => val?.length === 17 ) : Yup.string()
        //     .test(
        //       'len',
        //       i18n.t('common.isValidVin'),
        //       (val) => !val || val?.length === 17
        //     ),
        company: isInsuracePortalUser() ? Yup.string().required('common.fieldRequiredMessage') : Yup.string(),
        make: window.$environment?.SERVICE_PROVIDER === 'clutch' ? Yup.string().required(i18n.t('common.fieldRequiredMessage')) : '',
        model: window.$environment?.SERVICE_PROVIDER === 'clutch' ? Yup.string().required(i18n.t('common.fieldRequiredMessage')) : '',
      });
    }
  }, []);
  const { i18n } = useTranslation();

  const apiId = type + 'SaveTrailerData';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  // const [item, setItem] = useState({});
  const [validationlist, setValidationlist] = useState({});

  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => { }
  });
  const [open, setOpen] = useState(false);

  const objectArrayFromDiffFields = (list, value, field1, field2, callback) => {
    return list?.length
      ? list.map((item) => {
        if (callback) return callback(item, field1, field2, value);
        else
          return {
            label: field2 ? `${item[field1]} ${item[field2]}` : item[field1],
            value: item[value]
          };
      })
      : [];
  };

  const objectArrayFromSameField = (list) => {
    return list?.length
      ? list.map((item) => {
        return {
          label: item,
          value: item
        };
      })
      : [];
  };

  const vehicleList = objectArrayFromDiffFields(
    trailer?.vehicles,
    'vehicleId',
    'name'
  );

  const mechanicList = objectArrayFromDiffFields(
    trailer?.mechanics,
    'id',
    'firstName',
    'lastName'
  );
  const trailerTypes = objectArrayFromSameField(trailer?.trailerTypes);
  const [initialValues, setInitialValues] = useState({});
  const years = objectArrayFromSameField(trailer?.years);
  const defaultYear = () => {
    const currentYear = new Date().getFullYear();
    const defaultYear = years.find(o => o.value === currentYear);
    return defaultYear || years[0]
  }
  const trackingDeviceList = [
    {
      label: 'PT 10',
      value: 'PT10'
    },
    {
      label: 'Geometris',
      value: 'Geometris'
    }
  ]
  useEffect(() => {
    // setItem(trailer?.trailerDetails || {});
    const objectArrayFromDiffFields = (list, field, value) => {
      const lt = list?.length
        ? list.map((item) => {
          return {
            label: item[value],
            value: item[field]
          };
        })
        : [];
      return lt;
    };

    const inspectionForms = objectArrayFromDiffFields(
      trailer?.customDvirForms?.filter(ele => ele.unitType == 'TRAILER'),
      'idPub',
      'name'
    );
    setForms(inspectionForms)

    setInitialValues({
      wlId: trailer?.trailerDetails?.wlId,
      idPub: trailer?.trailerDetails?.idPub,
      trackingDevice: trailer?.trailerDetails?.trackingDevice
        ? trackingDeviceList.filter(
          (ele) => ele.value === trailer?.trailerDetails?.trackingDevice
        )[0]
        : '',
      isEdit: trailer?.trailerDetails?.isEdit,
      name: trailer?.trailerDetails?.name || '',
      companyId: trailer?.trailerDetails?.companyId || '',
      notes: trailer?.trailerDetails?.lienholder || '',
      company: trailer?.company
        ? {
          label: trailer.company.name,
          value: trailer.company.id
        }
        : '',
      type: trailer?.trailerDetails?.type
        ? {
          label: trailer?.trailerDetails?.type,
          value: trailer?.trailerDetails?.type
        }
        : trailerTypes[0],
      year: trailer?.trailerDetails?.year
        ? {
          label: trailer?.trailerDetails?.year,
          value: trailer?.trailerDetails?.year
        }
        : defaultYear(),
      vin: trailer?.trailerDetails?.vin || '',
      make: trailer?.trailerDetails?.make || '',
      model: trailer?.trailerDetails?.model || '',
      next_maintenance_date:
        trailer?.trailerDetails?.next_maintenance_date || '',
      plateNumber: trailer?.trailerDetails?.plateNumber || '',
      gvw: trailer?.trailerDetails?.gvw || '',
      mechanicId:
        mechanicList.filter(
          (mech) => mech.value === trailer?.trailerDetails?.mechanicId
        )[0] || '',
      vehicleIdPub:
        vehicleList.filter(
          (veh) => veh.value === trailer?.trailerDetails?.vehicleIdPub
        )[0] || '',
      esn: trailer?.trailerDetails?.esn || '',
      regionId:
        (regions &&
          regions.length &&
          regions.filter(
            (reg) => reg.value === trailer?.trailerDetails?.regionId
          )[0]) ||
        '',
      terminalId:
        (terminals &&
          terminals.length &&
          terminals.filter(
            (ter) => ter.value === trailer?.trailerDetails?.terminalId
          )[0]) ||
        '',
      departmentId:
        (departments &&
          departments.length &&
          departments.filter(
            (dep) => dep.value === trailer?.trailerDetails?.departmentId
          )[0]) ||
        '',
      subDepartmentId:
        (subDepartments &&
          subDepartments.length &&
          subDepartments.filter(
            (sub) => sub.value === trailer?.trailerDetails?.subDepartmentId
          )[0]) ||
        '',
      customDvirFormId:
        (trailer?.trailerDetails?.customDvirFormId &&
          inspectionForms.filter(
            (form) => form.value === trailer?.trailerDetails?.customDvirFormId
          )[0]) ||
        '',
    });
  }, [trailer?.trailerDetails]);

  const documentsList = trailer?.documents;
  const companies = objectArrayFromDiffFields(fleetCompanies, 'id', 'name')
  const submitTrailerData = (fields) => {
    {/* if (!document.querySelector("div[type='error']")){ */ }
    let finalData = {
      wlId: fields.wlId || null,
      type: fields.type?.value || null,
      vehicleIdPub: fields.vehicleIdPub?.value || null,
      trackingDevice: fields.trackingDevice?.value || null,
      vin: fields.vin,
      make: fields.make,
      model: fields.model,
      lienholder: fields.notes,
      plateNumber: fields.plateNumber,
      gvw: fields.gvw,
      mechanicId: fields.mechanicId?.value || null,
      name: fields.name,
      idPub: fields.idPub,
      year: fields.year?.value || null,
      isEdit: fields.isEdit,
      companyId: fields.company?.value || fields.companyId,
      esn: fields.esn,
      regionId: (fields.regionId && fields.regionId.value) || null,
      terminalId: (fields.terminalId && fields.terminalId.value) || null,
      departmentId:
        (fields.departmentId && fields.departmentId.value) || null,
      subDepartmentId:
        (fields.subDepartmentId && fields.subDepartmentId.value) || null,
      customDvirFormId: fields.customDvirFormId?.value || null,
    };
    if ((layout !== 'Clutch' || window.$environment?.SERVICE_PROVIDER === 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'nauto') && moment(fields?.next_maintenance_date).isValid()) {
      finalData.next_maintenance_date = moment(
        fields.next_maintenance_date
      ).format(BACKEND_DATE_FORMAT);
    } else if (moment(fields?.next_maintenance_date).isValid()) {
      finalData.next_maintenance_date = moment(
        fields.next_maintenance_date
      ).format(BACKEND_DATE_FORMAT);;
    } else finalData.next_maintenance_date = '';
    saveTrailerData({
      data: {
        data: {
          ...finalData
        }
      },
      apiId,
      callback: () => {
        history.push(pageUrls.vehicleList);
      }
    });
    {/* } */ }
  }
  return (
    <>
      <Form
        type="server"
        direction="column"
        initialValues={{ ...initialValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          if (!fields.isEdit && window?.$environment?.SERVICE_PROVIDER === 'riskTheory') {
            setModalListObject({
              content: () => (
                <div className="deleteParent">
                  <div className="imageSection">
                    <img
                      src={getImagePath(imageUrls.confirmIcon)}
                      alt="Are you sure"
                    />
                  </div>
                  <h2>
                    {i18n.t('common.insurancePortalWarning')}
                  </h2>
                  <div className="deletePopupBtn">
                    <div className="cancelBtn">
                      <Button
                        label={window?.$environment?.SERVICE_PROVIDER === 'riskTheory' ? i18n.t('common.ok') : i18n.t('common.cancel')}
                        type={'reset'}
                        onClick={() => {
                          setOpen(false);
                        }}
                      ></Button>
                    </div>
                    <div className="deletebtn">
                      <Button
                        label={window?.$environment?.SERVICE_PROVIDER === 'riskTheory' ? i18n.t('common.proceedToAdd') : i18n.t('common.ok')}
                        onClick={() => {
                          submitTrailerData(fields);
                          setOpen(false);
                        }}
                      ></Button>
                    </div>
                  </div>
                </div>
              )
            })
            !fields.isEdit && setOpen(true);
          } else {
            submitTrailerData(fields)
          }
        }}
        customProps={{
          forms,
          hasApiStatus,
          saveDocument,
          deleteDocument,
          apiStatus,
          callback,
          trailerTypes,
          fetchTerminals,
          fetchDepartments,
          fetchSubDepartments,
          setValidationlist,
          trackingDeviceList,
          companies,
          getRegions,
          recallUpdate,
          validationlist,
          years,
          mechanicList,
          vehicleList,
          trailer,
          regions,
          type,
          isValidVin,
          logBook,
          terminals,
          departments,
          subDepartments,
          disabledCriteria,
          documentsList,
          setInitialValues,
          Styled,
          downloadDocument
        }}
        handleOnCancel={() => {
          history.push(pageUrls.vehicleList);
        }}
        validationSchema={Yup.object().shape({
          ...validationlist
        })}
      />
      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
      <iframe id="downloadDocument" style={{ display: 'none' }}></iframe>
    </>
  );
};

TrailerForm.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};

export default TrailerForm;
