import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import React, { cloneElement, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { getLocalStorage } from '@/services/storage';
import BackDrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { connect } from 'react-redux';
import {
  COOKIE_AUTH_DETAILS,
  imageUrls,
  pageUrls,
  ROLES
} from '../../../config/constants/keys';
import { deleteCookie, getCookie } from '../../../services/cookie';
import {
  getAuthDetails,
  getEsKey,
  getGenerateModuleRelation,
  getImagePath,
  getLogo,
  hasRestrictedRole,
  isdvirOnly,
  setLanguage,
  setLanguageFromStorage,
  showLanguageCode
} from '../../../utils/utils';
import Styled from './privateStyle';

const layout = window?.$environment?.CURRENT_LAYOUT;
const ErrorIcon = lazy(() => import(`../../ErrorIcon/${layout}`));
const SuccessIcon = lazy(() => import(`../../SuccessIcon/${layout}`));
const Button = lazy(() => import(`../../UI/Button/${layout}`));
const Loader = require(`../../Loader`);
const Modal = require(`../../UI/Modal/${layout}`);
const LeftMenu = lazy(() => import(`../../LeftMenu/${layout}`));
const Link = lazy(() => import(`../../UI/Link`));
const SvgIcon = lazy(() => import(`../../SvgIcon/${layout}`));

const PrivateWrapper = ({
  children,
  layout,
  apiStatus,
  clearRedux,
  setAuth,
  showFilterPopup,
  folderPath,
  handleLogout,
  dashboard,
  fetchCountData,
  fetchDashboardData,
  keepi18nTextAsSame,
  profile
}) => {
  const lang = localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language')
  const [profileIcon, setProfileIcon] = useState('')
  const [leftMenuList, setLeftMenuList] = useState([]);
  useEffect(() => {
    let apiId = 'fetchCountApi';
    if (layout !== 'welcome' && dashboard?.statsCount?.fleetVehicles === '') {
      fetchDashboardData({ fetchApi: 'dashboardApi' });
      fetchCountData({
        apiId
      });
    }
  }, []);
  useEffect(() => {
    let code = setLanguageFromStorage(i18n, 'en');
    if (document.getElementById(code)) {
      document.getElementById(code).selected = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    const basePath = `config/menu${folderPath}${window.$environment.SERVICE_PROVIDER}`;
    const path =
      token?.previousPaymentFailed || token?.adminSuspended
        ? require(`@/${basePath}/resetPassword`)
        : layout === 'authenticatedStyle'
          ? require(`@/${basePath}/resetPassword`)
          : require(`@/${basePath}/leftMenu`);
    setLeftMenuList(getGenerateModuleRelation(path.default));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderPath]);

  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const history = useHistory();
  const { i18n } = useTranslation();
  const [loaderPopupOpen, setLoaderPopupOpen] = React.useState(false);
  const [openCallback, setOpenCallback] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  useEffect(() => {
    setLanguageFromStorage(i18n, 'en');
    document.addEventListener('click', (event) => {
      if (event?.target?.id !== 'profileIcon') {
        setOpenMenu(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => (
      <Button
        type="submit"
        label={i18n.t('common.ok')}
        onClick={() => setOpenCallback(false)}
      />
    )
  });
  const isServiceManager = hasRestrictedRole([ROLES.SERVICE_MANAGER]);
  const isDvirOnlyFlagEnable = isdvirOnly();
  const summaryItems = [
    {
      itemType: 'INSHOP',
      url: !isServiceManager ? pageUrls.vehicleNotInUse : null,
      name: i18n.t('dashboard.header.INSHOP'),
      show: true
    },
    {
      itemType: 'OUTOFSERVICE',
      url: !isServiceManager ? pageUrls.outOfService : null,
      name: i18n.t('dashboard.header.OUTOFSERVICE'),
      show: true
    },
    {
      itemType: 'AVAILABLETRUCKS',
      url: null,
      name: i18n.t('dashboard.header.AVAILABLETRUCKS'),
      show: !isServiceManager
    }
  ];
  useEffect(() => {
    let isPageLoading = false;
    for (let index = 0; index < apiStatus.status.length; index++) {
      const element = apiStatus.status[index];
      if (element.isLoading) {
        isPageLoading = true;
      }
      if (element.errorMessage === 'Access denied') {
        setLoaderPopupOpen(false);

        deleteCookie(COOKIE_AUTH_DETAILS);
        clearRedux();
        setAuth(null);
        window.location = pageUrls.login;
        return;
      }
    }
    isPageLoading ? setLoaderPopupOpen(true) : setLoaderPopupOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.status]);

  const bigDrawerWidth = '4.1%';
  const bigDrawerWidthNoUnit = '4.1';
  const smDrawerWidth = '4.1%';
  const smDrawerWidthNoUnit = '4.1';

  const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const md = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const lg = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const mdWidth = md ? smDrawerWidth : bigDrawerWidth;
  let width = sm ? bigDrawerWidth : mdWidth;
  const mdWidthNoUnit = md ? smDrawerWidthNoUnit : bigDrawerWidthNoUnit;
  let widthNoUnit = sm ? bigDrawerWidthNoUnit : mdWidthNoUnit;

  width = open ? width : '4.3%';
  widthNoUnit = open ? widthNoUnit : '4.3';
  const handleDrawerOpen = () => {
    document.getElementById('logoContainer').style.display = 'none';
    setOpen(true);
    setTimeout(() => {
      document.getElementById('logoContainer').style.display = 'block';
    }, 50);
  };

  const openLogo = open ? imageUrls.hamburger : imageUrls.hamburger;
  const logo = sm || md || lg ? imageUrls.hamburger : imageUrls.hamburger;

  const image = getImagePath(imageUrls.bg_image);

  const handleOnLanguage = () => {
    setLanguage(i18n);
    setOpenMenu(false);
  };
  const handleOnLogout = () => {
    const auth = getAuthDetails(false);
    if (auth) {
      handleLogout({
        apiId: 'logout',
        callback: (type, message) => {
          if (type === 'error') {
            callback(type, message);
          } else {
            setAuth(null);
            // history.push(pageUrls.login);
            window.location.href = pageUrls.login;
          }
        }
      });
    } else {
      window.location.href = pageUrls.login;
    }
  };
  const [username, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  useEffect(() => {
    const token = getCookie(COOKIE_AUTH_DETAILS);
    if (token) {
      setUserName(`${token.firstName} ${token.lastName}`);
      setCompanyName(token.companyName);
    }
    if (token && token.firstName) {
      let firstName = token.firstName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
      let lastName = token.lastName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
      if (lastName) {
        if (firstName) setProfileIcon(`${firstName[0]}${lastName[0]}`)
        else setProfileIcon(`${lastName[0]}`)
      } else if (firstName) {
        setProfileIcon(`${firstName[0]}`)
      }
    }
    // else {
    //   handleOnLogout();
    // }
  }, []);
  useEffect(() => {
    if (window.location.href.includes('resetPassword')) {
      if (profile) {
        setUserName(`${profile.firstName} ${profile.lastName}`);
        if (profile.firstName) {
          let firstName = profile.firstName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
          let lastName = profile.lastName.replace(/[^a-zA-Z0-9 ]/g, "").trim()
          if (lastName) {
            if (firstName) setProfileIcon(`${firstName[0]}${lastName[0]}`)
            else setProfileIcon(`${lastName[0]}`)
          } else if (firstName) {
            setProfileIcon(`${firstName[0]}`)
          }
        }
      }
    }
  }, [profile])

  const generateMenuItem = (value, index) => {
    return (
      <MenuItem
        onClick={value.handleOnClick}
        key={`${value.label}Label${index}`}
      >
        {value.icon ? (
          <ListItemIcon className="menuItem">
            {value.icon.indexOf('.svg') > -1 ? (
              <SvgIcon value={value.icon} />
            ) : (
              <img
                alt={'optionIcon' + value.label}
                src={getImagePath(value.icon)}
              />
            )}

            <span>
              {value.label === 'currentLanguage'
                ? i18n.t(`common.${showLanguageCode('en', keepi18nTextAsSame)}`)
                : value.label}
            </span>
          </ListItemIcon>
        ) : (
          <Link
            href={value.path || ''}
            onClick={(e) => {
              if (value.isFilter) {
                showFilterPopup();
                return false;
              } else if (value.handleOnClick) {
                value.handleOnClick();
                return false;
              } else if (value?.openInNewTab) {
                var win = window.open(
                  `${window.$environment?.FLEETOPS_URL}?token=${token?.token}&partnerId=${window.$environment?.DOMAIN}`,
                  '_blank'
                );
                win.focus();
                e.preventDefault();
                return false;
              }
            }}
          >
            {i18n.t(value.label)}
          </Link>
        )}
      </MenuItem>
    );
  };
  //Callback function to be invoked after an api call
  const callback = (type, message, callbackFunction, errors, optionButton, isListErr = false) => {
    if (message) {
      let keys = Object.keys(message);
      let items = Object.values(message);
      if (typeof message !== 'string' && keys.length > 1) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setModalListObject({
        header: '',
        content: () => (
          <div className="successpopup">
            {type === 'error' ? (
              <ErrorIcon />
            ) : type === 'warningfmcsa' ? (
              <img
                src={getImagePath(imageUrls.confirmIcon)}
                alt="Are you sure"
              />
            ) : (
              <SuccessIcon />
            )}
            {typeof message === 'string' ? (
              <h2> {i18n.t(getEsKey(message))} </h2>
            ) : keys.length === 1 && items[0].length === 1 && isListErr ? (
              <div className="errorSectionlist">
                {keys.map((item, index) => {
                  return (
                    <div className="listItem">
                      <span>Line No:{item}</span>
                      <ul>
                        {message[item].map((err, index) => {
                          return <li>{err}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : keys.length === 1 && items[0].length === 1 ? (
              <h2> {i18n.t(items)} </h2>
            ) : keys.length === 1 && items[0].length !== 1 ? (
              <div className="errorSectionlist">
                {keys.map((item, index) => {
                  return (
                    <div className="listItem">
                      <span>Line No:{item}</span>
                      <ul>
                        {message[item].map((err, index) => {
                          return <li>{err}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            ) : type !== 'error' && message?.error?.length === 0 ? (
              <h2> {i18n.t('common.dataImportSuccess')} </h2>
            ) : (
              <div className="errorSectionlist">
                {keys.map((item, index) => {
                  return (
                    <div className="listItem">
                      <span>Line No:{item}</span>
                      <ul>
                        {message[item].map((err, index) => {
                          return <li>{err}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            )}
            {
              optionButton ? <div className="deletePopupBtn">
              <div className="cancelBtn">
                <Button
                  label={i18n.t('Cancel')}
                  type="submit"
                  onClick={() => {
                    setOpenCallback(false);
                  }}
                ></Button>
              </div>
              <div className="deletebtn">
                <Button
                  type="submit"
                  label={i18n.t('common.deletePopup')}
                  onClick={() => {
                      callbackFunction()
                    }}
                  ></Button>
                </div>
              </div> 
              :
              <div className="deletePopupBtn">
                <Button
                  type="submit"
                  label={i18n.t('common.ok')}
                  onClick={() => {
                    setOpenCallback(false);
                    callbackFunction && callbackFunction();
                    if (
                      type !== 'error' &&
                      message?.type === 'Users' &&
                      window.location.href.includes('user')
                    )
                      history.push(pageUrls.userList);
                    else if (
                      type !== 'error' &&
                      (message?.type === 'Vehicles' ||
                        message?.type === 'Trailers') &&
                      window.location.href.includes('vehicle')
                    )
                      history.push(pageUrls.vehicleList);
                    else if (
                      type !== 'error' &&
                      window.location.href.includes('alerts') &&
                      window.location.href.includes('requests')
                    )
                      history.push(pageUrls.alertsRequests);
                    else if (
                      type !== 'error' &&
                      window.location.href.includes('company') &&
                      message === 'Your subscription has been canceled.'
                    )
                      handleOnLogout();
                    else if (
                      (type === 'mailIdChanged' || type !== 'error') &&
                      window.location.href.includes('user') &&
                      window.location.href.includes('profile') &&
                      message === 'Your profile was saved'
                    ) {
                      handleOnLogout();
                    } else if (
                      type !== 'error' &&
                      window.location.href.includes('user') &&
                      window.location.href.includes('resetPassword') &&
                      message === 'Your profile was saved'
                    ) {
                      handleOnLogout();
                    }
                  }}
                />
              </div>
           }
          </div>
        )
      });
      setOpenCallback(true);
    }
  };
  const token = getCookie(COOKIE_AUTH_DETAILS);

  const useStyles = makeStyles({
    backdrop: {
      position: 'absolute',
      zIndex: 2000
    }
  });
  const collapseStyles = useStyles();
  const [collapse, setCollapse] = useState(false);

  return (
    <Styled.ParentWrapper>
      <Styled.Wrapper>
        {hasRestrictedRole(['OWNER']) &&
          ((token?.onboardCompleted &&
            window.$environment?.SERVICE_PROVIDER === 'clutch') ||
            window.$environment?.SERVICE_PROVIDER !== 'clutch') ? (
          <>
            <Styled.ClutchHeader newUI={window?.$environment?.clutchNewUI}>
              <div className="logo-wrapper">
                <Box
                  id="logoContainer"
                  onClick={(e) => {
                    setCollapse((state) => !state);
                  }}
                >
                  <img
                    src={
                      collapse
                        ? getImagePath(imageUrls.closeIcon)
                        : getImagePath(logo)
                    }
                  // width={!open ? '80px' : ''}
                  />
                </Box>
                <div className="logoBox"><img
                  alt="logo"
                  src={getLogo(imageUrls.innerLogo)}
                  className="clutchLogo"
                  onClick={() => {
                    isDvirOnlyFlagEnable &&
                      !hasRestrictedRole(['SERVICE_MANAGER'])
                      ? history.push(pageUrls.pendingMechanicInspections)
                      : isDvirOnlyFlagEnable &&
                        hasRestrictedRole(['SERVICE_MANAGER'])
                        ? history.push(pageUrls.unassignedInspections)
                        : hasRestrictedRole(['DRIVER', 'MECHANIC'])
                          ? history.push(pageUrls.profile)
                          : history.push(pageUrls.dashboard);
                  }}
                ></img></div>
              </div>
              <div className="header-right">
                {!hasRestrictedRole(['DRIVER', 'MECHANIC']) && (
                  <div className="countWrapper">
                    {!isServiceManager && (
                      <span className="countItem">
                        <a
                          className="count"
                          href={`${pageUrls.userList}?role=driver`}
                        // onClick={() => history.push(pageUrls.userList)}
                        >
                          {dashboard?.statsCount?.fleetDrivers}
                        </a>
                        <span className="countText">
                          {i18n.t('fleetDrivers')}
                        </span>
                        <img
                          src={getImagePath(imageUrls.fleetDriver)}
                          alt="logedIcon"
                        />
                      </span>
                    )}
                    <span className="countItem">
                      <a
                        className={`count ${!isServiceManager ? '' : 'no-link'
                          }`}
                        href={
                          !isServiceManager ? `${pageUrls.vehicleList}` : null
                        }
                      // onClick={() => history.push(pageUrls.vehicleList)}
                      >
                        {dashboard?.statsCount?.fleetVehicles}
                      </a>
                      <span className="countText">
                        {i18n.t('fleetVehicles')}
                      </span>
                      <img
                        src={getImagePath(imageUrls.fleetVehicle)}
                        alt="logedIcon"
                      />
                    </span>
                    {isDvirOnlyFlagEnable &&
                      summaryItems.map((item) => {
                        const config =
                          dashboard?.data?.companySummary?.items?.find(
                            (config) => config.itemType === item.itemType
                          );
                        return (
                          item.show && (
                            <span className="countItem without-logo">
                              <a
                                className={`count ${(!config || config?.hasLink) &&
                                    !isServiceManager
                                    ? ''
                                    : 'no-link'
                                  }`}
                                href={item?.url}
                              >
                                {config?.count ? config?.count : 0}
                              </a>
                              <span className="countText">{item?.name}</span>
                            </span>
                          )
                        );
                      })}
                  </div>
                )}
                <div className="userprofile">
                  {isDvirOnlyFlagEnable && (
                    <span className="nameSection" title={companyName}>
                      {companyName}
                    </span>
                  )}
                  <span
                    className="nameSection nameSection--newUI"
                    title={username}
                    onClick={() => history.push(pageUrls.profile)}
                  >
                    {' '}
                    {username}
                  </span>
                  <div className="profileWrapper">
                    <span
                      onClick={() => {
                        setOpenMenu(true);
                        openMenu && setOpenMenu(false);
                      }}
                      id="profileIcon"
                      className="profileIconLogo">
                      {profileIcon}
                    </span>
                    {openMenu && (
                      <Styled.OptionContainer id="menu">
                        {[
                          {
                            icon: imageUrls.account,
                            label: i18n.t('common.account'),
                            handleOnClick: () => {
                              history.push(pageUrls.profile);
                              setOpenMenu(false);
                            },
                            roles: []
                          },
                          {
                            icon:
                              getLocalStorage('Language') === 'en'
                                ? imageUrls.language
                                : imageUrls.languageEn,
                            label: 'currentLanguage',
                            handleOnClick: handleOnLanguage,
                            roles: []
                          }
                        ].map((item, index) => {
                          return generateMenuItem(item, index);
                        })}
                      </Styled.OptionContainer>
                    )}
                  </div>
                  <span
                    className="labelLogout"
                    onClick={() => {
                      handleOnLogout();
                    }}
                  ></span>
                </div>
              </div>
            </Styled.ClutchHeader>
            {window.$environment?.SERVICE_PROVIDER === 'clutch' && (
              <Styled.FloatContainer
                onClick={() =>
                  window.open('http://h48e7h7dlki.clutcheld.com/', '_blank')
                }
              >
                <img src={getImagePath(imageUrls.helpIcon)} alt="Help Icon" />
              </Styled.FloatContainer>
            )}
          </>
        ) : hasRestrictedRole(['OWNER']) &&
          !token?.onboardCompleted ? null : !hasRestrictedRole(['OWNER']) &&
            layout !== 'welcome' ? (
          <>
            <Styled.ClutchHeader newUI={window?.$environment?.clutchNewUI}>
              <div className="logo-wrapper">
                <Box id="logoContainer">
                  <img
                    src={getImagePath(logo)}
                  // width={!open ? '80px' : ''}
                  />
                </Box>
                <div className="logoBox"><img
                  alt="logo"
                  src={getLogo(imageUrls.innerLogo)}
                  className="clutchLogo"
                  onClick={() => {
                    isDvirOnlyFlagEnable &&
                      !hasRestrictedRole(['SERVICE_MANAGER'])
                      ? history.push(pageUrls.pendingMechanicInspections)
                      : isDvirOnlyFlagEnable &&
                        hasRestrictedRole(['SERVICE_MANAGER'])
                        ? history.push(pageUrls.unassignedInspections)
                        : hasRestrictedRole(['DRIVER', 'MECHANIC'])
                          ? history.push(pageUrls.profile)
                          : history.push(pageUrls.dashboard);
                  }}
                ></img></div>
              </div>
              <div className="header-right">
                {!hasRestrictedRole(['DRIVER', 'MECHANIC']) && (
                  <div className="countWrapper">
                    {!isServiceManager && (
                      <span className="countItem">
                        <a
                          className="count"
                          href={`${pageUrls.userList}?role=driver`}
                        >
                          {dashboard?.statsCount?.fleetDrivers}
                        </a>
                        <span className="countText">
                          {i18n.t('fleetDrivers')}
                        </span>
                        <img
                          src={getImagePath(imageUrls.fleetDriver)}
                          alt="logedIcon"
                        />
                      </span>
                    )}
                    <span className="countItem">
                      <a
                        className={`count ${!isServiceManager ? '' : 'no-link'
                          }`}
                        href={
                          !isServiceManager ? `${pageUrls.vehicleList}` : null
                        }
                      // onClick={() => history.push(pageUrls.vehicleList)}
                      >
                        {dashboard?.statsCount?.fleetVehicles}
                      </a>
                      <span className="countText">
                        {i18n.t('fleetVehicles')}
                      </span>
                      <img
                        src={getImagePath(imageUrls.fleetVehicle)}
                        alt="logedIcon"
                      />
                    </span>
                    {isDvirOnlyFlagEnable &&
                      summaryItems.map((item) => {
                        const config =
                          dashboard?.data?.companySummary?.items?.find(
                            (config) => config.itemType === item.itemType
                          );
                        return (
                          item.show && (
                            <span className="countItem without-logo">
                              <a
                                className={`count ${(!config || config?.hasLink) &&
                                    !isServiceManager
                                    ? ''
                                    : 'no-link'
                                  }`}
                                href={item?.url}
                              >
                                {config?.count ? config?.count : 0}
                              </a>
                              <span className="countText">{item?.name}</span>
                            </span>
                          )
                        );
                      })}
                  </div>
                )}
                <div className="userprofile">
                  {isDvirOnlyFlagEnable && (
                    <span className="nameSection" title={companyName}>
                      {companyName}
                    </span>
                  )}
                  <span
                    className="nameSection"
                    title={username}
                    onClick={() => history.push(pageUrls.profile)}
                  >
                    {' '}
                    {username}
                  </span>
                  <div className="profileWrapper">
                    <span
                      onClick={() => {
                        setOpenMenu(true);
                        openMenu && setOpenMenu(false);
                      }}
                      id="profileIcon"
                      className="profileIconLogo">
                      {profileIcon}
                    </span>

                    {openMenu && (
                      <Styled.OptionContainer id="menu">
                        {[
                          {
                            icon: imageUrls.account,
                            label: i18n.t('common.account'),
                            handleOnClick: () => {
                              history.push(pageUrls.profile);
                              setOpenMenu(false);
                            },
                            roles: []
                          },
                          {
                            icon:
                              getLocalStorage('Language') === 'en'
                                ? imageUrls.language
                                : imageUrls.languageEn,
                            label: 'currentLanguage',
                            handleOnClick: handleOnLanguage,
                            roles: []
                          }
                        ].map((item, index) => {
                          return generateMenuItem(item, index);
                        })}
                      </Styled.OptionContainer>
                    )}
                  </div>
                  <span
                    className="labelLogout"
                    onClick={() => {
                      handleOnLogout();
                    }}
                  ></span>
                </div>
              </div>
            </Styled.ClutchHeader>
            {window.$environment?.SERVICE_PROVIDER === 'clutch' && (
              <Styled.FloatContainer
                onClick={() =>
                  window.open('http://h48e7h7dlki.clutcheld.com/', '_blank')
                }
              >
                <img src={getImagePath(imageUrls.helpIcon)} alt="Help Icon" />
              </Styled.FloatContainer>
            )}
          </>
        ) : null}
        <Styled.Main
          widthnounit={widthNoUnit}
          drawerwidth={width}
          image={image}
        >
          <Styled.LeftDrawer
            drawerwidth={width}
            widthnounit={widthNoUnit}
            newUI={window?.$environment?.clutchNewUI}
            collapse={collapse}
          >
            <Collapse in={true} collapsedHeight="100%">
              <Box>
                {layout !== 'authenticatedWithoutMenu' && (
                  <LeftMenu
                    needToolTip={false}
                    showFilterPopup={showFilterPopup}
                    leftMenuList={leftMenuList}
                    noAuthCheck={layout === 'authenticatedStyle'}
                    collapsible={true}
                    open={collapse}
                  />
                )}
              </Box>
            </Collapse>
          </Styled.LeftDrawer>
          <BackDrop
            open={collapse}
            className={collapseStyles.backdrop}
            onClick={() => setCollapse((state) => !state)}
          />
          <Styled.Content>
            {cloneElement(children, { callback })}
          </Styled.Content>
        </Styled.Main>
        <Modal.default
          open={loaderPopupOpen}
          setOpen={setLoaderPopupOpen}
          data={{
            header: '',
            content: () => <Loader.default lang={lang} />
          }}
          isLoader
          newUI={window?.$environment?.clutchNewUI}
        />
        <Modal.default
          open={openCallback}
          setOpen={setOpenCallback}
          data={ModalListObject}
          isSuccess
          isError={isError}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
        />
      </Styled.Wrapper>
    </Styled.ParentWrapper>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    dashboard: state.dashboard,
    profile: state.resetPassword
  };
};
const mapDispatch = (dispatch) => {
  return {
    clearRedux: () => dispatch({ type: 'RESET_APP' }),
    handleLogout: (data) => dispatch.login.handleLogout(data),
    fetchCountData: (data) => dispatch.dashboard.fetchCountData(data),
    fetchDashboardData: (data) => dispatch.dashboard.fetchData(data)
  };
};

export default connect(mapState, mapDispatch)(PrivateWrapper);

PrivateWrapper.defaultProps = {
  children: null,
  auth: null,
  layout: ''
};
PrivateWrapper.propTypes = {
  children: PropTypes.element,
  auth: PropTypes.object,
  layout: PropTypes.string,
  clearRedux: PropTypes.func.isRequired,
  setAuth: PropTypes.func.isRequired
};
