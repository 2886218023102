export const COOKIE_AUTH_DETAILS = `${window?.$environment?.SERVICE_PROVIDER.toLocaleUpperCase()}_AUTH_DETAILS`;
export const COOKIE_CLIENT_AUTH_DETAILS = `${window?.$environment?.SERVICE_PROVIDER.toLocaleUpperCase()}_CLIENT_AUTH_DETAILS`;
export const AUTH_TOKEN = 'token';

export const COOKIE_ENCRYPT_KEY = '123';
export const COOKIE_EXPIRY = 'Thu, 01 Jan 1970 00:00:00 UTC';
export const ROLES = {
  ADMIN: 'ADMIN',
  SERVICE_MANAGER: 'SERVICE_MANAGER',
  VIEW_ONLY_FM: 'VIEWONLY_FLMANAGER',
  SUPER_ADMIN: 'SUPER_ADMIN',
  UNDER_WRITER: 'UNDER_WRITER',
  LOSS_CONTROL: 'LOSS_CONTROL',
  CLAIM_ADJUSTER: 'CLAIM_ADJUSTER',
};

export const imageUrls = {
  profile: 'icons/user_profile.jpeg',
  smallLogo: 'smallLogo.png',
  downloadCenter: 'icons/downloadCenter.svg',
  logo: 'logo.png',
  logs: 'logs.svg',
  user: 'user.svg',
  vehicle: 'vehicle.svg',
  accident: 'icons/accident.svg',
  accidentSelected: 'icons/accidentSelected.svg',
  repair: 'repair.svg',
  exportIcon: 'icons/export-grey.png',
  toolsIcon: 'icons/tools.png',
  closeIcon: 'icons/close-popup.png',
  popupClose: 'icons/popupClose.png',
  deleteIcon: 'icons/delete.png',
  eldIcon: 'eldIcon.jpg',
  bg_image: 'bg_log.jpg',
  appStore: 'appstore.png',
  googleplay: 'googleplay.png',
  exportAll: 'icons/Exportall_grey.png',
  account: 'account.svg',
  language: 'es.svg',
  languageEn: 'en.svg',
  oldView: 'switch.svg',
  messaging: 'messaging.svg',
  logout: 'logout.svg',
  importUser: 'icons/importUser.png',
  exportUser: 'icons/exportUser.png',
  importUserTemplate: 'icons/excelNew.png',
  arrowdownIcon: 'icons/arrow-down.png',
  edit: window.$environment.SERVICE_PROVIDER == 'nauto' ? 'icons/editLogIcon.svg' : 'icons/edit.png',
  delete: 'icons/delete.png',
  undelete: 'icons/undelete.png',
  notFull: 'icons/notFull.png',
  full: 'icons/full.png',
  addUser: 'addUser.png',
  import: 'icons/import.png',
  closeWhite: 'icons/closeWhite.png',
  backButton: 'icons/backButton.png',
  sendMsg: 'icons/messageSender.png',
  closeMap: 'icons/closeMap.png',
  addVehicle: 'icons/addVehicle.svg',
  uploadIcon: 'icons/upload.svg',
  updateIcon: 'icons/update.svg',
  downloadIcon: 'icons/download.png',
  uploaddownloadIcon: 'icons/deleteUpload.png',
  viewMoreIcon: 'icons/viewAll.png',
  clearAll: 'icons/clearAll.png',
  confirmIcon: 'popupConfirm.svg',
  deletePopup: 'popupDelete.svg',
  addOrange: 'icons/addOrange.png',
  refreshIcon: 'icons/update.png',
  searchIcon: 'icons/searchGrey.png',
  clutchLogo: 'icons/clutch_logo.png',
  passwordIcon: 'icons/passwordIcon.png',
  sixPin: 'icons/sixPin.png',
  ninePin: 'icons/ninePin.png',
  dualCam: 'icons/dualCam.png',
  volvo: 'icons/volvo.png',
  obdii: 'icons/qbdii.png',
  mack: 'icons/mack.png',
  bgClutch: 'clutchLogin.png',
  logoutIcon: 'icons/logoutIcon.png',
  logedIcon: 'icons/logedIcon.png',
  innerLogo: 'icons/innerLogo.png',
  fleetDriver: 'icons/fleetdriverIcon.svg',
  fleetVehicle: 'icons/fleetvehiclesIcon.svg',
  mapGreen: 'icons/spotlightGreen.png',
  mapRed: 'icons/spotlightRed.png',
  mapGrey: 'icons/spotlightGrey.png',
  mapCluster: 'icons/clusterIcon.png',
  dashboardIcon: 'icons/dashboardIcon.svg',
  dashboardSelectedIcon: 'icons/dashboardSelected.svg',
  logsIcon: 'icons/logsIcon.svg',
  logsSelectedIcon: 'icons/logSelected.svg',
  userIcon: 'icons/userIcon.svg',
  userIconSelected: 'icons/userSelected.svg',
  vehiclesIcon: 'icons/vehicleIcon.svg',
  vehiclesIconSelected: 'icons/vehicleSelected.svg',
  reportsIcon: 'icons/reportIcon.svg',
  reportsIconSelected: 'icons/reportSelected.svg',
  companyIcon: 'icons/companyIcon.svg',
  companyIconSelected: 'icons/companyselectedIcon.svg',
  dotAuditIcon: 'icons/dotauditIcon.svg',
  dotAuditIconSelected: 'icons/dotSelected.svg',
  supportIcon: 'icons/supportIcon.svg',
  supportIconSelected: 'icons/supportSelected.svg',
  logHistory: 'icons/logHistory.svg',
  editLogIcon: 'icons/editLogIcon.svg',
  viewAllMap: 'icons/viewallMap.svg',
  tickIcon: 'icons/tick.png',
  completegreyIcons: 'complete-grey.png',
  completeorangeIcons: 'complete-Orange.png',
  progressgreyIcons: 'in_progress_grey.png',
  progressorangeIcons: 'in_progress_orange.png',
  closedOrangeIcons: 'closed-Orange.png',
  closedgreyIcons: 'closed-grey.png',
  notstartedIcons: 'not_started_grey.png',
  notstartedorangeIcons: 'not_started_orange.png',
  awaitinggreyIcons: 'awaiting_parts_grey.png',
  awaitingorangeIcons: 'awaiting_parts_orange.png',
  importExcel: 'icons/Excel.png',
  helpIcon: 'icons/help-icon.svg',
  viewReport: 'icons/View_Report_Grey.png',
  viewPicture: 'icons/View_Pic_Grey.png',
  chatIcon: 'icons/chat-icon.png',
  arrowupIcon: 'icons/arrow-up.png',
  departmentTool: 'tools_black.png',
  importUserold: 'importUserold.png',
  deleteDepartment: 'departmentClose.png',
  editDepartment: 'departmetEdit.png',
  poweredBy: 'gorilla_poweredby.png',
  firstPageIcon: 'icons/page-first.png',
  prevPageIcon: 'icons/page-prev.png',
  nextPageIcon: 'icons/page-next.png',
  lastPageIcon: 'icons/page-last.png',
  firstPageDisabledIcon: 'icons/page-first-disabled.png',
  prevPageDisabledIcon: 'icons/page-prev-disabled.png',
  nextPageDisabledIcon: 'icons/page-next-disabled.png',
  lastPageDisabledIcon: 'icons/page-last-disabled.png',
  historyPdficon: 'icons/file-pdf-regular.svg',
  logbookIcon: 'icons/book-solid.svg',
  saveUdlog: 'icons/save-regular.svg',
  toggleOn: 'icons/toggle-on.svg',
  toggleOff: 'icons/toggle-off.svg',
  inspections: 'icons/inspection-dvir.svg',
  inspectionIconSelected: 'icons/inspectionSelectedDvir.svg',
  departments: 'icons/departments.svg',
  departmentSelected: 'icons/departmentsSelected.svg',
  companyList: 'icons/company.svg',
  companyListSelected: 'icons/companySelected.svg',
  dailyDocumentSelected: 'icons/viewReportsSelected.svg',
  dailyDocument: 'icons/viewReports.svg',
  newWorkOrder: 'icons/New.png',
  completedWorkOrder: 'icons/Completed.png',
  awaitingPartsWorkOrder: 'icons/AwaitingParts.png',
  inProgressWorkOrder: 'icons/InProgress.png',
  scheduledWorkOrder: 'icons/Scheduled.png',
  approvedWorkOrder: 'icons/Approved.svg',
  closedWorkOrder: 'icons/Closed.svg',
  hamburger: 'icons/hamburger.png',
  loginbg: 'login-bg.png',
  scoreCard: 'icons/scoreCard.png',
  fleetCompanies: 'icons/fleetCompanies.svg',
  upcomingRenewal: 'icons/renewal.svg',
  scoreCardSelected: "icons/scoreCardSelected.svg",
  scoreCardIcon: "icons/scoreCard.svg",
  cameraIcon: 'icons/cameraSelected.png',
  cameraIcon_Selected: 'icons/camera_selected.svg',
  cameraIcon_Unselected: 'icons/camera_unselected.svg',
  viewStatus: 'icons/viewStatus.svg',
};
export const getPageUrls = (file) => {
  try {
    const pageUrls =
      require(`@/routes/web/${window?.$environment?.SERVICE_PROVIDER}/${file}`).default;
    return pageUrls;
  } catch (error) {
    return [];
  }
};
const publicUrls = getPageUrls('publicConfig');
const privateUrls = getPageUrls('privateConfig');
const commonUrls = getPageUrls('commonConfig');

export const getUrls = (config) => {
  const result = config.map((item) => {
    return {
      [item.module]: item.url
    };
  });
  return Object.assign({}, ...result);
};
const urls = getUrls([...publicUrls, ...privateUrls, ...commonUrls]);
export const pageUrls = {
  ...urls,
  notFound: '/notfound'
};

export const periodList = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Yearly', value: 'YEARLY' }
]

export const severityList = [
  {
    label: 'Less than 33%',
    value: 'LESS_THAN_33_PERCENTAGE'
  },
  {
    label: '33% to 67%',
    value: 'BETWEEN_33_TO_67_PERCENTAGE'
  },
  {
    label: 'Greater than 67%',
    value: 'GREATER_THAN_67_PERCENTAGE'
  },
  // commenting according to ticket FLEETMG-16582 
  // {
  //   label: 'Speeding',
  //   value: 'SPEEDING'
  // },
  {
    label: 'Accident',
    value: 'ACCIDENT'
  },
  {
    label: 'Hard',
    value: 'HARD'
  },
  {
    label: 'Harsh',
    value: 'HARSH'
  },
  {
    label: 'Severe',
    value: 'SEVERE'
  }
]

export const recurrenceTypes = [
  { label: 'Does not repeat', value: 'NONE' },
  { label: 'One-Time', value: 'ONE_TIME' },
  { label: 'Recurring', value: 'RECURRING' }
]

export const recurrenceIntervals = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Daily', value: 'DAILY' }
]

export const chartTypes = {
  BAR_CHART: 'barChartList',
  PIE_CHART: 'pieChartList',
  SCATTER_PLOT_CHART: 'scatterPlotList'
}

export const behaviourList = [
  {
    value: 'ACCELERATION',
    label: 'Acceleration'
  },
  {
    label: 'Accident',
    value: 'ACCIDENT'
  },
  {
    label: 'Braking',
    value: 'BRAKING'
  },
  {
    label: 'Radius',
    value: 'RADIUS'
  },
  // commented according to ticket FLEETMG-16582
  // {
  //   label: 'Speeding',
  //   value: 'SPEEDING'
  // },
  {
    label: 'Turning',
    value: 'TURNING'
  },
]

export const externalUrls = {
  gs: {
    appStore:
      'https://apps.apple.com/gb/app/gorilla-fleet-intelligence/id1449686307',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.app.gorillafleetintelligence'
  },
  clutch: {
    appStore:
      'https://itunes.apple.com/gb/app/gorilla-fleet-intelligence/id1449686307?mt=8',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.app.gorillafleetintelligence'
  },
  em: {
    appStore: 'https://apps.apple.com/gb/app/eld-mandate-pro/id1491823700',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.app.eldmandatepro'
  },
  trackon: {
    appStore: 'https://apps.apple.com/us/app/trackon-hos/id1241911012',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.app.trackon'
  },
  spireon: {
    appStore: 'https://apps.apple.com/us/app/fleetlocate-shift/id1278396252',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.app.fleetlocate'
  },
  tp: {
    appStore:
      'https://apps.apple.com/gb/app/gorilla-fleet-intelligence/id1488420043',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.app.truckerpatheld'
  }
};
