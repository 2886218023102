import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .manageSchedulingWrapper{
    margin-bottom: 15px;
    display: flex;
    justify-content: end;
  }
padding: 3%;
.MuiAccordion-root.Mui-expanded {
    margin: 18px 0;
}
.fullWidth{
    width:100%;
}
.scheduleDateWrapper{
    width: 28%;
}
.radioWrapper{
    width:100%;
    margin-bottom: 10px !important;
    .label{
        align-items: center;
        display: flex;
        flex: 0 0 7% !important;
        color: ${theme.colors.PRIMARY_CONTRAST};
    }
    .recurrenceIntervalBox {
        display: block !important;
    }
    .infoText{
        color: ${theme.colors.SECONDARY_MAIN};
    }
}
.recurrenceIntervalBoxLabel{
    padding-bottom: 25px;;
}
.MuiPaper-root {
    background: ${theme.colors.HEADER_COLOR} !important;
    border: none !important;
    .MuiAccordionSummary-content h2 {
        font-size: 23px;
        color: ${theme.colors.LINK_COLOR} !important;
        text-transform: uppercase;
    }
    .MuiIconButton-label {
        .MuiSvgIcon-root {
            fill: #c0bbb7;
        }
    }
    .MuiAccordionSummary-root.Mui-expanded {
        min-height: 48px !important;
    }
    .MuiCollapse-wrapperInner {
        background-color: ${theme.colors.ACCORDION_BODY_BACKGROUND ? theme.colors.ACCORDION_BODY_BACKGROUND : theme.colors.BODY_BACKGROUND};
    }
    .MuiAccordionDetails-root {
        form {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-top: 1%;
            .buttonWrapper {
                justify-content: end;
                button {
                    margin-top: 29px;
                }
            }
            .buttonWrapper,
            .fieldWrapper {
                flex-direction: column;
                flex: 0 0 28%;
                margin-right: 1%;
                align-self: flex-start;
            }
            .fieldWrapper {
                .MuiFormLabel-root {
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        /* IE10+ CSS styles go here */
                        padding-bottom: 18px;
                    }
                }
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    /* IE10+ CSS styles go here */
                    margin-bottom: 73px;
                }
            }
        }
    }
}
  `}
`;

const Styled = { Wrapper };

export default Styled;
