import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { imageUrls } from '../../../config/constants/keys';
import { getImagePath } from '../../../utils/utils';
import Table from '../Dashboard/DriverStatus';
import Styled from './DriverStatusListStyle';
const layout = window?.$environment?.CURRENT_LAYOUT;

const GoogleMap = lazy(() => import(`../../../components/GoogleMap/${layout}`));
const Search = lazy(() =>
  import(`../../../components/UI/SimpleSearch/${layout}`)
);
const Icon = lazy(() => import(`../../../components/UI/Icon/${layout}`));

const Component = ({ list, changeRequest }) => {
  const i18n = useTranslation();

  const selectChange = (e) => {
    changeRequest({ search: e });
  };
  return (
    <>
      <Styled.Wrapper>
        <Styled.SearchWrapper>
          <Search
            onChange={(e) => selectChange(e)}
            default={list}
            fromData={false}
            placeHolder={i18n.t('common.driverName')}
            query={'fullName'}
            width="35%"
            id='driver_status'
          />
        </Styled.SearchWrapper>
        <Styled.MapWrapper>
          {window.$environment?.SERVICE_PROVIDER !== 'cyntrx' && window.$environment.SERVICE_PROVIDER != 'nauto' &&<GoogleMap type={'driverStatusMap'} height={'400px'} />}
        </Styled.MapWrapper>
        <Styled.TableWrapper>
          <Icon
            src={getImagePath(imageUrls.refreshIcon)}
            onClick={() => changeRequest()}
          />
          <span className="tooltiptext">{i18n.t('common.refresh')}</span>
          <Table driverStatus={list} />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

export default Component;

Component.propTypes = {
  list: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired
};
