import { imageUrls, pageUrls } from '@/config/constants/keys';
import { getImagePath, hasRestrictedRole } from '@/utils/utils';
import { Link as LinkLi } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { lazy, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Filters from '../Filters';
const layout = window?.$environment?.CURRENT_LAYOUT;

const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const Component = ({
  trailers,
  handleDeleteAlert,
  callback,
  history,
  Styled,
  insuracePortalUserRole,
  fleetCompanyList,
  filterList,
  handleReset
}) => {
  const { i18n } = useTranslation();
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {}
  });
  const [open, setOpen] = useState(false);
  const [values, setInitialValues] = useState({
    companyId: null,
    unit_id: null
  });

  const columns = [
    ...(insuracePortalUserRole? 
      [
        {
        id: 'companyName',
        numeric: false,
        disablePadding: true,
        label: i18n.t('policyHolder'),
        needSorting: true
      }]: []),
    {
      id: 'unit_id',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.unitId'),
      needSorting: true
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.type'),
      needSorting: true
    },

    {
      id: 'vin',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.vin'),
      needSorting: true
    },
    {
      id: 'year',
      numeric: true,
      disablePadding: false,
      label: i18n.t('vehicles.year'),
      needSorting: true
    },
    {
      id: 'manufact',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.manufacturer'),
      needSorting: true
    },
    {
      id: 'model',
      numeric: false,
      disablePadding: false,
      label: i18n.t('vehicles.model'),
      needSorting: true
    },
    {
      id: 'actions',
      label: i18n.t('Actions')
    }
  ];
  const generateRows = () => {
    const updatedList = trailers.map((item) => {
      if(item?.vin){
      let lastFour = item.vin.toString().substr(item.vin.length - 4);
      item.vin = `...${lastFour}`
      }
      return item;
    });
    return updatedList;
  };
  const information = i18n.t('common.tableEmptyMessage');
  return (
    <>
      <Styled.Wrapper>
        <Styled.TableWrapper insuracePortalUserRole={insuracePortalUserRole}>
          {insuracePortalUserRole && (
            <Filters 
              fleetCompanyList={fleetCompanyList} 
              filterList={filterList} 
              handleReset={handleReset} 
              values={values} 
              setInitialValues={setInitialValues}
            />
          )}
          <Table
            type="client"
            defaultSortBy={{ sortBy: 'unit_id', order: 'asc' }}
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={window.$environment.CURRENT_LAYOUT === 'OldUi' ? 5 : 10}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <LinkLi
                      component={Link}
                      to={pageUrls.editTrailer.replace(':id', row.id)}
                    >
                      <Icon src={getImagePath(imageUrls.edit)} />
                    </LinkLi>
                  );
                }
              },
              ...!(window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])) ? [{
                icon: (row, index) => {
                  return (
                    <Icon
                      src={getImagePath(imageUrls.delete)}
                      onClick={() => {
                        setModalListObject({
                          content: () => (
                            <div className="deleteParent">
                              <div className="imageSection">
                                <img
                                  src={getImagePath(imageUrls.confirmIcon)}
                                  alt="Are you sure"
                                />
                              </div>
                              <h2> {i18n.t('common.sureConfirm')} </h2>
                              <p> {i18n.t('trailers.recoverConfirm')}</p>
                              <div className="deletePopupBtn">
                                <div className="cancelBtn">
                                  <Button
                                    label={i18n.t('Cancel')}
                                    type={'reset'}
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  ></Button>
                                </div>
                                <div className="deletebtn">
                                  <Button
                                    label={i18n.t('common.deletePopup')}
                                    onClick={() => {
                                      handleDeleteAlert({
                                        id: 'deleteAlert',
                                        data: { id: row.id },
                                        callback: callback
                                      });
                                      setOpen(false);
                                    }}
                                  ></Button>
                                </div>
                              </div>
                            </div>
                          )
                        });
                        setOpen(true);
                      }}
                    />
                  );
                }
              }] : []
            ]}
            defaultSort={'unit_id'}
            i18n={i18n}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
      <Modal open={open} setOpen={setOpen} data={ModalListObject} deletePopup />
    </>
  );
};

export default Component;

Component.propTypes = {
  history: PropTypes.object.isRequired
};
