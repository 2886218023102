import { getLocalStorage } from '@/services/storage';
// const defaultDev = 'emdev.gorkillasafety.org';
// const defaultDev = 'clutch.gorillasafety.com';
// const defaultDev = 'webfleet.gorillasafety.com';
// const defaultDev = 'compliance-stage.spireon.com';
// const defaultDev = 'risktheory.gorillasafety.com';
// const defaultDev = 'trackondev.gorillasafety.org';
// const defaultDev = 'cyntrx.gorillasafety.com';
// const defaultDev = 'cyntrxdvir.gorillasafety.com';
// const defaultDev = 'risktheory.gorillasafety.com';
// const defaultDev = 'staging.gorillasafety.com';
const defaultDev = 'nauto.gorillasafety.org';

const defaultProduction = 'client.gorillasafety.com';
const defaultStaging = 'spireoneld.gorillasafety.com';
const defaultPreprod = 'preprod.gorillasafety.org';

const fleetOpsUrl = {
  development: 'http://eldloads-staging.fleetops.ai',
  staging: 'http://staging.eldloads.com',
  preprod: 'http://eldloads-staging.fleetops.ai',
  production: 'https://app.eldloads.com/',
}
export default (environment, domain) => {
  const defaultEnv =
    environment === 'development'
      ? defaultDev
      : ''
  const environmentUrl = require(`./${environment}`).default;
  let domainData = environmentUrl[domain]
    ? {
        ...environmentUrl[domain],
        CURRENT_LAYOUT: environmentUrl[domain]?.APP_LAYOUT,
        DOMAIN: domain,
        FLEETOPS_URL: fleetOpsUrl[environment],
      }
    : undefined;

  let defaultData = environmentUrl[defaultEnv]
    ? {
        ...environmentUrl[defaultEnv],
        CURRENT_LAYOUT: environmentUrl[defaultEnv]?.APP_LAYOUT,
        DOMAIN: defaultEnv,
        FLEETOPS_URL: fleetOpsUrl[environment],
      }
    : undefined;

  window.$environment = domainData || defaultData;
  let currentLayout = getLocalStorage('currentLayout');
  domainData = domainData
    ? {
        ...domainData,
        CURRENT_LAYOUT:
          window.$environment.SERVICE_PROVIDER === 'spireon' &&
          window.location.pathname.indexOf('/spireon') < 0
            ? domainData.SWITCH_APP_LAYOUT
            : currentLayout || domainData.CURRENT_LAYOUT,
          FLEETOPS_URL: fleetOpsUrl[environment],
      }
    : domainData;
  defaultData = defaultData
    ? {
        ...defaultData,
        CURRENT_LAYOUT:
          window.$environment?.SERVICE_PROVIDER === 'spireon' &&
          window.location.pathname.indexOf('/spireon') < 0
            ? defaultData?.SWITCH_APP_LAYOUT
            : currentLayout || defaultData?.CURRENT_LAYOUT,
        FLEETOPS_URL: fleetOpsUrl[environment],
      }
    : defaultData;
  window.$environment = domainData || defaultData;
};
