import { getImagePath } from '@/utils/utils';
import Box from '@material-ui/core/Box';
import React from 'react';
import styled from 'styled-components';
const isEs = () => window.$environment?.SERVICE_PROVIDER == 'webfleet' && localStorage.getItem(window.$environment?.SERVICE_PROVIDER + 'Language') == 'es'

const LoaderContainer = styled(Box)`
  background: no-repeat url(${getImagePath(isEs() ? 'ajax-Loader_es.gif' :'ajax-Loader.gif')}) center;
  ${({ theme }) => `${theme?.colors?.PRIMARY_MODAL_MAIN ? `background-color: ${theme?.colors?.PRIMARY_MODAL_MAIN};` : ``}`}
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Loader = (props) => {
  return <LoaderContainer />
}

export default Loader;
