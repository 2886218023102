import React, { lazy, useRef, useState } from 'react';

import { pageUrls, ROLES } from '@/config/constants/keys';
import { hasRestrictedRole, isInsuracePortalUser } from '@/utils/utils';
import { useLocation, useParams } from 'react-router-dom';

const policyHolderAddURL="/policyholders/add";
const layout = window?.$environment?.CURRENT_LAYOUT;
const serviceProvider = window.$environment?.SERVICE_PROVIDER;
const isInsurancePortalUser = isInsuracePortalUser();

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const Modal = require(`@/components/UI/Modal/${layout}`);
const Payment = lazy(() => import(`@/components/Payment/${layout}`));

const TopActions = ({ i18n, updateCard, callback, Styled, history,updateCardDetails}) => {
const [open, setOpen] = useState(false);
const [ModalListObject, setModalListObject] = useState({})
const paymentRef = useRef();
const params = useParams();
const location= useLocation();
  const updateId = 'updateCard';


  const cardDetails = (data) => {
    let obj = {
      cardHolderName: data.cardHolderName,
      stripeToken: data.stripeToken
    };
    let apiId = 'updateCardDetails';
    updateCardDetails({
      apiId,
      data: obj,
      callback: callback,
      history: history,
      i18n: i18n
    });
    setOpen(false);
  };

  return (
    <Styled.Wrapper>
    <FormStyle.ButtonsWrapper className="creditCardWrapper">
    {
      serviceProvider === 'riskTheory' && !hasRestrictedRole([ROLES.SERVICE_MANAGER])&& location.pathname!== policyHolderAddURL && !isInsurancePortalUser
      &&
      <Button
      id="creditCardUpdate"
      type="submit"
      label={i18n.t('companyData.updateCreditCardDetails')}
      onClick={() => {
        setModalListObject({
          content: () => {
            return (
              <>
                <Payment
                  getEmail
                  ref={paymentRef}
                  buttonLabel={'Update Credit Card Details'}
                  validationMessage={i18n.t(
                    'common.fieldRequiredMessage'
                  )}
                  updateCard={(data) => cardDetails(data)}
                  handleOnChange={(event, type) => {}}
                  validationMessageMail={i18n.t(
                    'common.emailValidationMessage'
                  )}
                  hidepaymentButton
                />
                <div className="deletebtn companyDataCard">
                  <Button
                    label={window.$environment.SERVICE_PROVIDER==="riskTheory"? i18n.t(
                      'common.update'
                    ):i18n.t(
                      'companyData.updateCreditCardDetails'
                    )}
                    onClick={() => {
                      paymentRef.current && paymentRef.current.handleSubmit(() => {});
                    }}
                  ></Button>
                </div>
              </>
            );
          }
        });
        setOpen(true);
      }}
    />
      
    }
      {serviceProvider === 'clutch' && !hasRestrictedRole([ROLES.SERVICE_MANAGER]) ? (
        <Button
          id="creditCardUpdate"
          type="submit"
          label={i18n.t('companyData.updateCardDetails')}
          onClick={() => {
            updateCard({
              updateId,
              callback: callback
            });
          }}
        />
      ) : (serviceProvider !== 'em' && serviceProvider !== 'tp' && serviceProvider!== 'cyntrx' && serviceProvider !== 'nauto' && serviceProvider!== 'edvir' && serviceProvider!== 'webfleet' && serviceProvider!== 'at' && serviceProvider!== 'eldx' && serviceProvider!== 'simplex' && serviceProvider!== 'azuga' && serviceProvider !== 'trackit' && serviceProvider !== 'ft' && serviceProvider !== 'hcss' && serviceProvider !== 'patriot' && !hasRestrictedRole([ROLES.SERVICE_MANAGER])) 
      ? (
        ((serviceProvider==="riskTheory" && location.pathname!== policyHolderAddURL)||(serviceProvider!=="riskTheory") )&&
        <Button
          id="creditCardUpdate"
          type="submit"
          label={i18n.t('companyData.paymentInformation')}
          onClick={() => {
            history.push(`${pageUrls.paymentInformation}${isInsurancePortalUser && params?`?companyId=${params?.id}`:``}`);
          }}
        />
      ) : null}
    </FormStyle.ButtonsWrapper>
    <Modal.default
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        withCloseIcon
        alignItemsLeft
        companyData
        width={720}
      />
    </Styled.Wrapper>
  );
};
export default TopActions;
