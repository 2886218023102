const routeConfigs = [
    {
      url: '/subscriptions',
      roles: ['FLMANAGER', 'ADMIN'],
      component: 'Subscriptions',
      title: 'Subscription Tier',
      module: 'subscriptionLink'
    }
  ];
  
  export default routeConfigs;
  