import React, { lazy, useState } from 'react';

import {
  hasEnabledFeature,
  objectArrayFromMultipleFields
} from '@/utils/utils';
import ViewForm from './Form';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const AdvancedDvir = lazy(() => import(`@/components/modules/AdvancedDvir`));
const CustomDvir = lazy(() => import(`@/components/modules/CustomDvir`));
const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));

const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const DatePopup = lazy(() =>
  import(`@/components/modules/DatePopup/${layout}`)
);

const TopActions = ({
  isCustomDviRequired,
  isAdvancedDviRequired,
  i18n,
  history,
  list,
  bulkDownloadReport,
  apiStatus,
  clearApiStatus,
  callback,
  Styled,
  changeRequest,
  hasApiStatus,
  filters,
  setPagination
}) => {
  const [isExportClicked, setIsExportClicked] = useState(false);

  const userList = objectArrayFromMultipleFields(list?.drivers, 'id', [
    'firstName',
    'lastName'
  ]);
  const downloadTypes = [
    {label: 'DVIR' ,value:'DVIR'},
    {label: 'POST-TRIP' ,value:'POST_TRIP'},
    {label: 'PRE-TRIP' ,value:'PRE_TRIP'},
  ];

  return (
    <Styled.Wrapper>
      {layout !== 'OldUi' && <Breadcrumb filters={filters} />}
      <div className="buttonWrapper">
      <FormStyle.default.ButtonsWrapper className="advancedDVIR">
        {isCustomDviRequired && (
            <CustomDvir
              className="buttonStyle"
              label={i18n.t('inspections.customDvir')}
              history={history}
            />
          )}
         
        </FormStyle.default.ButtonsWrapper>
        <FormStyle.default.ButtonsWrapper className={window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? "" :  "advancedDVIR"}>
          {isAdvancedDviRequired && (
            <AdvancedDvir
              className="buttonStyle"
              label={i18n.t('inspections.advancedDvir')}
              history={history}
            />
          )}
        </FormStyle.default.ButtonsWrapper>

        <FormStyle.default.ButtonsWrapper className="bulkDownload">
          {hasEnabledFeature('bulkDownloadEnabled') && (
            <Button
              type="submit"
              className="buttonStyle"
              label={i18n.t('inspections.bulkDownloadReport')}
              onClick={() => {
                if (window.$environment.SERVICE_PROVIDER === 'spireon') {
                  setIsExportClicked(!isExportClicked);
                } else {
                  setIsExportClicked(true);
                }
              }}
            />
          )}
        </FormStyle.default.ButtonsWrapper>
      </div>
      <DatePopup
        formId="bulkDownloadForm"
        isOpen={isExportClicked}
        setIsOpen={setIsExportClicked}
        submit={bulkDownloadReport}
        apiStatus={apiStatus}
        clearApiStatus={clearApiStatus}
        data={list?.list || []}
        callback={callback}
        drivers={userList}
        downloadTypes={downloadTypes}
        inspections
      />
      <ViewForm
        apiStatus={apiStatus}
        history={history}
        callback={callback}
        Styled={Styled}
        hasApiStatus={hasApiStatus}
        changeRequest={changeRequest}
        list={list}
        setPagination={setPagination}
      />
      <div className="breadcrumbClass">
        {layout === 'OldUi' && <Breadcrumb filters={filters} />}
      </div>
    </Styled.Wrapper>
  );
};
export default TopActions;
