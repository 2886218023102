import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { number, string } from 'yup';

import { ROLES } from '@/config/constants/keys';
import {
  UI_DATE_FORMAT,
  UI_DATE_LOWER_FORMAT
} from '@/config/constants/static';
import { hasEnabledFeature, hasRestrictedRole, hasValue, isInsuracePortalUser } from '@/utils/utils';
import moment from 'moment';
import { getDateLocale, getFilterLocaleValues, isdvirOnly } from '../../../utils/utils';
// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const insuracePortalUserRole = isInsuracePortalUser()
const Department = lazy(() => import(`@/components/modules/Department`));
const UploadList = lazy(() => import(`../DocumentUploadList`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction,
  isCameraEnabled
}) => {
  const {
    i18n,
    terminals,
    departments,
    subDepartments,
    disabledCriteria,
    regions,
    documentsList,
    Styled,
    getRegions,
    recallUpdate,
    states,
    radiusCompliances
  } = customProps;
  useEffect(() => {
    if (documentsList) {
      let otherDocs = [];
      let vehicleDocs = [];
      for (let i = 0; i < documentsList.length; i++) {
        const item = documentsList[i];
        if (item.doctype === 'VEHICLE') {
          vehicleDocs.push(item);
        } else {
          otherDocs.push(item);
        }
      }
      setUploadList({
        otherDocs,
        vehicleDocs,
        currentDocs: [...vehicleDocs, ...otherDocs]
      });
      const noDocuments = uploadList.currentDocs.some(o => o.id)
      setIsDocumentAvailable(noDocuments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsList]);
  const isDVIROnlyFlagEnabled = isdvirOnly()
  const isWebfleet = window?.$environment?.SERVICE_PROVIDER === 'webfleet'
  const isRiskTheoryIsFLmanagar = window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])
  const [isDocumentAvailable, setIsDocumentAvailable] = useState(true);

  const deviceTypeField = {
    hardwareDeviceKey: string().required(i18n.t('common.fieldRequiredMessage'))
  };

  const esnField = {
    esn: string().required(i18n.t('common.fieldRequiredMessage'))
  };
  const [commonFields, setcommonFields] = useState({});
  useEffect(() => {
    if (layout !== 'Clutch') {
      setcommonFields({
        type: string().required(i18n.t('common.fieldRequiredMessage')),
        name: string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .max(10, i18n.t('common.validName')),
        vin: window.$environment.SERVICE_PROVIDER !== 'spireon' ? string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .matches(/[^\WqioqIOQ]+$/, i18n.t('common.isValidVin'))
          .test(
            'len',
            i18n.t('common.isValidVin'),
            (val) => val?.length === 17
          ) : string().when(["deviceType"], {
            is: (value) => value === 'ELD',
            then: string().required(i18n.t('common.fieldRequiredMessage'))
              .matches(/[^\WqioqIOQ]+$/, i18n.t('common.isValidVin'))
              .test(
                'len',
                i18n.t('common.isValidVin'),
                (val) => val?.length === 17
              ),
            otherwise: string()
          }),
        make: string().required(i18n.t('common.fieldRequiredMessage')),
        model: string().required(i18n.t('common.fieldRequiredMessage')),
        next_maintenance_date: string().required(
          i18n.t('common.fieldRequiredMessage')
        ),
        nextMaintenanceMileage: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        esn: string().when(["hardwareDeviceKey", "deviceType"], {
          is: (value1, value2) => ((value2 === 'ELD' && value1?.value === 7001) || (value2 === 'ELD' && value1?.value === 7007) || (value2 === 'ELD' && value1?.value === 7010) || value2 === 'Tracking'),
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        trackingDevice: string().when(["deviceType"], {
          is: (value) => value === 'Tracking',
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        currentOdometer: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        ...(checkCameraFeature(isCameraEnabled) ? {
          cameraSerialNo: string().when('camera', {
            is: 'Yes',
            then: string()
              .trim()
              .required('common.fieldRequiredMessage')
              .matches(/^[a-zA-Z0-9\-]+$/, 'Only alphanumeric characters and hyphens are allowed')
              .min(1, 'Camera serial must be at least 1 character')
              .max(50, 'Camera serial must be at most 50 characters'),
              otherwise: string()
          }),
          cameraPassword: string().when(["camera"], {
            is: (value) => value === 'Yes',
            then: string().trim().required('common.fieldRequiredMessage')
            .min(1, 'Password must be at least 1 character')
            .max(50, 'Password must be at most 50 characters'),
            otherwise: string()
          }),
          plateNumber: string().required(i18n.t('common.fieldRequiredMessage')),
        } : {}),
      });
    } else {
      setcommonFields(isWebfleet ? {
        esn: string().when(["hardwareDeviceKey", "deviceType"], {
          is: (value1, value2) => ((value2 === 'ELD' && value1?.value === 7001) || (value2 === 'ELD' && value1?.value === 7007) || (value2 === 'ELD' && value1?.value === 7010) || value2 === 'Tracking'),
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        trackingDevice: string().when(["deviceType"], {
          is: (value) => value === 'Tracking',
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
      } : {
        type: string().required(i18n.t('common.fieldRequiredMessage')),
        name: string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .max(10, i18n.t('common.validName')),
        vin: string()
          .required(i18n.t('common.fieldRequiredMessage'))
          .matches(/[^\WqioqIOQ]+$/, i18n.t('common.isValidVin'))
          .test(
            'len',
            i18n.t('common.isValidVin'),
            (val) => val?.length === 17
          ),
        company: insuracePortalUserRole ? string().required('common.fieldRequiredMessage') : string(),
        make: string().required(i18n.t('common.fieldRequiredMessage')),
        model: string().required(i18n.t('common.fieldRequiredMessage')),
        nextMaintenanceMileage: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        currentOdometer: number()
          .typeError(i18n.t('common.validNumberMessage'))
          .positive(i18n.t('common.positiveNumberMessage')),
        esn: string().when(["hardwareDeviceKey", "deviceType"], {
          is: (value1, value2) => ((value2 === 'ELD' && value1?.value === 7001) || (value2 === 'ELD' && value1?.value === 7007) || (value2 === 'ELD' && value1?.value === 7010) || value2 === 'Tracking'),
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        trackingDevice: string().when(["deviceType"], {
          is: (value) => value === 'Tracking',
          then: string().required('common.fieldRequiredMessage'),
          otherwise: string()
        }),
        ...(checkCameraFeature(isCameraEnabled) ? {
          cameraSerialNo: string().when('camera', {
            is: 'Yes',
            then: string()
              .trim()
              .required('common.fieldRequiredMessage')
              .matches(/^[a-zA-Z0-9\-]+$/, 'Only alphanumeric characters and hyphens are allowed')
              .min(1, 'Camera serial must be at least 1 character')
              .max(50, 'Camera serial must be at most 50 characters'),
              otherwise: string()
          }),
          cameraPassword: string().when(["camera"], {
            is: (value) => value === 'Yes',
            then: string().trim().required('common.fieldRequiredMessage')
            .min(1, 'Password must be at least 1 character')
            .max(50, 'Password must be at most 50 characters'),
            otherwise: string()
          }),
          plateNumber: string().required(i18n.t('common.fieldRequiredMessage')),
        } : {}),
        ...(window?.$environment?.SERVICE_PROVIDER === 'riskTheory' ? {
          garagingAddress: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          garagingCity: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          garagingState: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          garagingZipCode: string().required(
            i18n.t('common.fieldRequiredMessage')
          ),
          radiusCompliance: string().required(
            i18n.t('common.fieldRequiredMessage')
          )
        } : {})
      });
    }
  }, [layout]);
  const checkCameraFeature = (value) => {
    if (window.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole([ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER])) {
      return value;
    }
    return values.cameraIntegrationEnabled || hasEnabledFeature('cameraIntegrationEnabled')
  }

  const setValidations = (val, type) => {
    customProps.setValidationlist({
      ...commonFields
    });
    if (type === 'deviceKey' && val === 7001) {
      customProps.setValidationlist({
        ...commonFields,
        // ...esnField
      });
    }
    if (type === 'deviceKey' && val === 7007) {
      customProps.setValidationlist({
        ...commonFields,
        // ...esnField
      });
    }
    if (type === 'deviceType' && val === 'ELD') {
      customProps.setValidationlist({
        ...commonFields,
        ...deviceTypeField
      });
    }
  };

  const [uploadList, setUploadList] = useState({ currentDocs: [] });

  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };

  useEffect(() => {
    const noDocuments = uploadList.currentDocs.some(o => o.id)
    setIsDocumentAvailable(noDocuments)
  }, [uploadList])
  return (
    <>
      <Styled.Wrapper className="vehicleWrap">
        <Styled.LeftWrapper>
          <div className={customProps?.newUI ? 'section-wrapper' : ''}>
            <SubHeader
              text={i18n.t('common.vehicleInformation')}
              className="subheaderSection vehicleHeading"
              variant="h1"
            />
            <div className="formWrap">
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.vehicleType')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="type"
                    onChange={(event) => {
                      handleEdit(event, { field: 'type' });
                      customProps.setInitialValues({
                        ...values,
                        type: event
                      });
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={getFilterLocaleValues([values['type']], i18n)}
                    suggestions={getFilterLocaleValues(customProps.vehicleTypes, i18n)}
                    disabled={isWebfleet}
                  />
                  {showError(
                    'type',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.unitId')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="name"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        name: event
                      });
                      handleEdit(event, { field: 'name' });
                    }}
                    as={Input}
                    value={values['name']}
                    type="text"
                    disabled={isWebfleet}
                  />
                  {showError(
                    'name',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {window.$environment?.SERVICE_PROVIDER === 'riskTheory' &&
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('vehicles.wlId')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="wlId"
                      handleOnChange={(event) => {
                        customProps.setInitialValues({
                          ...values,
                          wlId: event
                        });
                        handleEdit(event, { field: 'wlId' });
                      }}
                      as={Input}
                      value={values['wlId']}
                      type="text"
                      disabled={isWebfleet || isRiskTheoryIsFLmanagar}
                    />
                    {showError(
                      'wlId',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>}
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.vin')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="vin"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        vin: event
                      });
                      handleEdit(event, { field: 'vin' });
                    }}
                    onBlur={(event) =>
                      customProps?.isValidVin({
                        apiId: 'isValidVIN',
                        data: {
                          vin: event,
                          idPub: values?.idPub,
                          isEdit: values?.isEdit,
                          companyId: values?.companyId
                        }
                      })
                    }
                    disabled={values?.isEdit ? 'disabled' : ''}
                    as={Input}
                    value={values['vin']}
                    type="text"
                  />
                  {values['vin'] &&
                    showError(
                      'vinError',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  {showError(
                    'vin',
                    errors,
                    isNotValid,
                    hasValue(customProps.apiStatus.status, 'api', 'isValidVIN')
                      ?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.fuelType')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="fuelType"
                    onChange={(event) => {
                      handleEdit(event, { field: 'fuelType' });
                      customProps.setInitialValues({
                        ...values,
                        fuelType: event
                      });
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={getFilterLocaleValues([values['fuelType']], i18n)}
                    suggestions={getFilterLocaleValues(customProps.fuelTypes, i18n)}
                    disabled={isWebfleet}
                  />
                  {showError(
                    'fuelType',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.year')}</Label>
                <FormStyle.default.TextWrapper className="yearSelect">
                  <Field
                    name="year"
                    onChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        year: event
                      });
                      handleEdit(event, { field: 'year' });
                    }}
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={values['year']}
                    suggestions={customProps.years}
                    disabled={isWebfleet || isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'year',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.make')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="make"
                    handleOnChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        make: event
                      });
                      handleEdit(event, { field: 'make' });
                    }}
                    as={Input}
                    value={values['make']}
                    type="text"
                    disabled={isWebfleet || isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'make',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.model')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="model"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'model' });

                      customProps.setInitialValues({
                        ...values,
                        model: event
                      });
                    }}
                    as={Input}
                    value={values['model']}
                    type="text"
                    disabled={isWebfleet || isRiskTheoryIsFLmanagar}
                  />
                  {showError(
                    'model',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.plateNumber')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="plateNumber"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'plateNumber' });
                      customProps.setInitialValues({
                        ...values,
                        plateNumber: event
                      });
                    }}
                    as={Input}
                    value={values['plateNumber']}
                    type="text"
                    disabled={isWebfleet}
                  />
                  {showError(
                    'plateNumber',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {!isDVIROnlyFlagEnabled &&
                (<FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('common.gvw')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="gvw"
                      handleOnChange={(event) => {
                        customProps.setInitialValues({
                          ...values,
                          gvw: event
                        });
                        handleEdit(event, { field: 'gvw' });
                      }}
                      as={Input}
                      value={values['gvw']}
                      type="text"
                      disabled={isWebfleet || isRiskTheoryIsFLmanagar}
                    />
                    {showError(
                      'gvw',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>)}
              {window.$environment?.SERVICE_PROVIDER !== 'clutch' && window.$environment?.SERVICE_PROVIDER !== 'riskTheory' && window.$environment?.SERVICE_PROVIDER !== 'webfleet' && !isDVIROnlyFlagEnabled && (
                <>
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">
                      {i18n.t('common.nextMaintenanceDate')}
                    </Label>
                    <FormStyle.default.TextWrapper className="yearSelect">
                      <Field
                        name="nextMaintenanceDate"
                        onChange={(event) => {
                          handleEdit(event, {
                            field: 'next_maintenance_date',
                            type: 'date'
                          });

                          customProps.setInitialValues({
                            ...values,
                            next_maintenance_date: moment(event).format(
                              UI_DATE_FORMAT
                            )
                          });
                        }}
                        locale={getDateLocale()}
                        as={ReactDatePicker}
                        placeholderText={UI_DATE_LOWER_FORMAT}
                        value={values['next_maintenance_date']}
                        type="date"
                        showPopperArrow={false}
                        showTimeSelect={false}
                        minDate={new Date()}
                        showDisabledMonthNavigation
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        isClearable={true}
                      />
                      {showError(
                        'next_maintenance_date',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>

                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">
                      {i18n.t('common.nextMaintenanceMileage')}
                    </Label>
                    <FormStyle.default.TextareaWrapper>
                      <Field
                        name="nextMaintenanceMileage"
                        handleOnChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            nextMaintenanceMileage: event
                          });

                          handleEdit(event, { field: 'nextMaintenanceMileage' });
                        }}
                        as={Input}
                        value={values['nextMaintenanceMileage']}
                        type="nextMaintenanceMileage"
                      />

                      {showError(
                        'nextMaintenanceMileage',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextareaWrapper>
                  </FormStyle.default.FieldsWrapper>
                </>
              )}
              {checkCameraFeature(isCameraEnabled) && (
                <>
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.camera')}</Label>
                    <FormStyle.default.CheckBoxWrapper direction="column">
                      <RadioButton
                        value={values['camera']}
                        options={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                        onClick={(event) => {
                          handleEdit(customProps?.cameraTypes[0], { field: 'cameraType' });
                          handleEdit('', { field: 'cameraPassword' });
                          handleEdit('', { field: 'cameraSerialNo' });
                          handleEdit(event, { field: 'camera' });
                          setValidations(event, 'camera');
                          customProps.setInitialValues({
                            ...values,
                            camera: event,
                            cameraPassword: '',
                            cameraType: event === "Yes" ? customProps?.cameraTypes[0] : null,
                            cameraSerialNo: '',
                          });
                        }}
                      />
                    </FormStyle.default.CheckBoxWrapper>
                  </FormStyle.default.FieldsWrapper>
                  {values['camera'] === 'Yes' && <>
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('vehicles.cameraType')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="cameraType"
                          onChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              cameraType: event,
                            });
                            handleEdit(event, { field: 'cameraType' });
                          }}
                          as={Select}
                          placeholder={i18n.t('common.pleaseSelect')}
                          value={values['cameraType']}
                          suggestions={customProps.cameraTypes}
                        />
                        {showError(
                          'cameraType',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  </>}
                  {values['camera'] === 'Yes' && <><FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.cameraSerialNo')}</Label>
                    <FormStyle.default.TextareaWrapper>
                      <Field
                        name="cameraSerialNo"
                        handleOnChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            cameraSerialNo: event
                          });

                          handleEdit(event, { field: 'cameraSerialNo' });
                        }}
                        as={Input}
                        value={values['cameraSerialNo']}
                        type="text"
                      />

                      {showError(
                        'cameraSerialNo',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextareaWrapper>
                  </FormStyle.default.FieldsWrapper>
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('common.cameraPassword')}</Label>
                      <FormStyle.default.TextareaWrapper>
                        <Field
                          name="cameraPassword"
                          handleOnChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              cameraPassword: event
                            });

                            handleEdit(event, { field: 'cameraPassword' });
                          }}
                          as={Input}
                          value={values['cameraPassword']}
                          type="text"
                        />

                        {showError(
                          'cameraPassword',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextareaWrapper>
                    </FormStyle.default.FieldsWrapper></>}
                </>
              )}
              {!isDVIROnlyFlagEnabled && (
                <>
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.currentOdometer')}</Label>
                    <FormStyle.default.TextareaWrapper>
                      <Field
                        name="currentOdometer"
                        handleOnChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            currentOdometer: event
                          });

                          handleEdit(event, { field: 'currentOdometer' });
                        }}
                        as={Input}
                        value={values['currentOdometer']}
                        type="text"
                        disabled={isWebfleet}
                      />

                      {showError(
                        'currentOdometer',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextareaWrapper>
                  </FormStyle.default.FieldsWrapper>

                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.notes')}</Label>
                    <FormStyle.default.TextareaWrapper>
                      <Field
                        name="notes"
                        handleOnChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            notes: event
                          });

                          handleEdit(event, { field: 'notes' });
                        }}
                        as={Input}
                        multiLine
                        value={values['notes']}
                        type="text"
                        disabled={isWebfleet}
                      />

                      {showError(
                        'notes',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextareaWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.deviceType')}</Label>
                    <FormStyle.default.CheckBoxWrapper direction="column">
                      <RadioButton
                        value={values['deviceType']}
                        options={[
                          { label: 'ELD', value: 'ELD' },
                          ...(window.$environment.SERVICE_PROVIDER == 'patriot' ? [{ label: 'Tracking', value: 'Tracking' }]: []),
                          { label: i18n.t('NONE'), value: 'NONE' }
                        ]}
                        disabled={window.location.href.includes('edit') && isWebfleet}
                        onClick={(event) => {
                          handleEdit(event, { field: 'deviceType' });
                          handleEdit('', { field: 'esn' });
                          setValidations(event, 'deviceType');
                          customProps.setInitialValues({
                            ...values,
                            deviceType: event,
                            hardwareDeviceKey: event === 'NONE' ? '' : values.hardwareDeviceKey,
                            esn: '',
                          });
                        }}
                      />
                    </FormStyle.default.CheckBoxWrapper>
                  </FormStyle.default.FieldsWrapper>

                  {values['deviceType'] === 'Tracking' && (
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('Tracking Device')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="trackingDevice"
                          onChange={(event) => {
                            handleEdit(event, { field: 'trackingDevice' });
                            setValidations(event?.value, 'deviceKey');
                            handleEdit('', { field: 'esn' });
                            customProps.setInitialValues({
                              ...values,
                              trackingDevice: event,
                              esn: '',
                            });
                          }}
                          as={Select}
                          value={getFilterLocaleValues([values['trackingDevice']], i18n)}
                          suggestions={getFilterLocaleValues(customProps.trackingDeviceList, i18n)}
                        />
                        {showError(
                          'trackingDevice',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  )}

                  {values['deviceType'] === 'ELD' && (
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('common.hardwareDevice')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="hardwareDeviceKey"
                          onChange={(event) => {
                            handleEdit(event, { field: 'hardwareDeviceKey' });
                            setValidations(event?.value, 'deviceKey');
                            handleEdit('', { field: 'esn' });
                            customProps.setInitialValues({
                              ...values,
                              hardwareDeviceKey: event,
                              esn: '',
                            });
                          }}
                          as={Select}
                          value={getFilterLocaleValues([values['hardwareDeviceKey']], i18n)}
                          suggestions={getFilterLocaleValues(customProps.hardwareDeviceList, i18n)}
                        />
                        {showError(
                          'hardwareDeviceKey',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  )}
                  {values['hardwareDeviceKey']?.value === 7001 || values['hardwareDeviceKey']?.value === 7007  || values['hardwareDeviceKey']?.value === 7010|| values['deviceType'] === 'Tracking' ? (
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('common.deviceSn')}</Label>
                      <FormStyle.default.TextareaWrapper>
                        <Field
                          name="esn"
                          handleOnChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              esn: event
                            });

                            handleEdit(event, { field: 'esn' });
                          }}
                          as={Input}
                          value={values['esn']}
                          type="text"
                        />

                        {showError(
                          'esn',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextareaWrapper>
                    </FormStyle.default.FieldsWrapper>
                  ) : null}
                </>)}
              {!isDVIROnlyFlagEnabled && (
                <>
                  {insuracePortalUserRole &&
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('policyHolder')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="company"
                          onChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              company: event
                            });
                            handleEdit(event, { field: 'company' });
                            getRegions(event.value)
                            recallUpdate(event.value)
                          }}
                          disabled={window.location.href.includes('edit')}
                          as={Select}
                          placeholder={i18n.t('common.pleaseSelect')}
                          value={values['company']}
                          suggestions={customProps.companies}
                        />
                        {showError(
                          'company',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  }
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('vehicles.drivers')}</Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="driver"
                        onChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            driversIds: event
                          });

                          handleEdit(event, { field: 'driversIds' });
                        }}
                        isMulti
                        isClearable
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['driversIds']}
                        suggestions={customProps.driverList}
                        disabled={isWebfleet}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  {(window.$environment?.SERVICE_PROVIDER !== 'clutch' && window.$environment?.SERVICE_PROVIDER !== 'cyntrx' && window.$environment?.SERVICE_PROVIDER !== 'nauto' && window.$environment?.SERVICE_PROVIDER !== 'webfleet' && window.$environment?.SERVICE_PROVIDER !== 'riskTheory') && (
                    <FormStyle.default.FieldsWrapper width="100%">
                      <Label variant="body1">{i18n.t('vehicles.mechanics')}</Label>
                      <FormStyle.default.TextWrapper>
                        <Field
                          name="mechanicId"
                          onChange={(event) => {
                            customProps.setInitialValues({
                              ...values,
                              mechanicId: event
                            });

                            handleEdit(event, { field: 'mechanicId' });
                          }}
                          as={Select}
                          placeholder={i18n.t('common.pleaseSelect')}
                          value={values['mechanicId']}
                          suggestions={customProps.mechanicList}
                          isClearable
                        />
                        {showError(
                          'mechanicId',
                          errors,
                          isNotValid,
                          customProps.hasApiStatus?.errorMessage
                        )}
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  )}
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.trailerName')}</Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="trailerIdPubs"
                        onChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            trailerIdPubs: event
                          });
                          handleEdit(event, { field: 'trailerIdPubs' });
                        }}
                        isMulti
                        isClearable
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['trailerIdPubs']}
                        suggestions={customProps.trailerList}
                      />
                      {showError(
                        'trailerIdPubs',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </>
              )}
              {!isDVIROnlyFlagEnabled && window?.$environment?.SERVICE_PROVIDER !== 'webfleet' && (
                <Department
                  values={values}
                  handleEdit={handleEdit}
                  i18n={i18n}
                  fetchTerminals={customProps.fetchTerminals}
                  fetchDepartments={customProps.fetchDepartments}
                  fetchSubDepartments={customProps.fetchSubDepartments}
                  regions={regions}
                  terminals={terminals}
                  departments={departments}
                  subDepartments={subDepartments}
                  disabledCriteria={disabledCriteria}
                />
              )}
              {hasRestrictedRole(['FLMANAGER']) &&
                hasEnabledFeature('customDvirEnabled') && (
                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">
                      {i18n.t('vehicles.inspectionForm')}
                    </Label>
                    <FormStyle.default.TextWrapper>
                      <Field
                        name="customDvirFormId"
                        onChange={(event) => {
                          customProps.setInitialValues({
                            ...values,
                            customDvirFormId: event
                          });

                          handleEdit(event, { field: 'customDvirFormId' });
                        }}
                        isClearable
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['customDvirFormId']}
                        suggestions={customProps.forms}
                        disabled={isWebfleet}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                )}
            </div>
          </div>
        </Styled.LeftWrapper>
        {window.$environment?.SERVICE_PROVIDER !== 'simplex' && <Styled.RightWrapper>
          {isDVIROnlyFlagEnabled && (
            <Styled.DVIRRightWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('vehicles.drivers')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="driver"
                    onChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        driversIds: event
                      });

                      handleEdit(event, { field: 'driversIds' });
                    }}
                    isMulti
                    isClearable
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={values['driversIds']}
                    suggestions={customProps.driverList}
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.trailerName')}</Label>
                <FormStyle.default.TextWrapper>
                  <Field
                    name="trailerIdPubs"
                    onChange={(event) => {
                      customProps.setInitialValues({
                        ...values,
                        trailerIdPubs: event
                      });
                      handleEdit(event, { field: 'trailerIdPubs' });
                    }}
                    isMulti
                    isClearable
                    as={Select}
                    placeholder={i18n.t('common.pleaseSelect')}
                    value={values['trailerIdPubs']}
                    suggestions={customProps.trailerList}
                  />
                  {showError(
                    'trailerIdPubs',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {!hasRestrictedRole(['FLMANAGER']) && (
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('vehicles.mechanics')}</Label>
                  <FormStyle.default.TextWrapper>
                    <Field
                      name="mechanicId"
                      onChange={(event) => {
                        customProps.setInitialValues({
                          ...values,
                          mechanicId: event
                        });

                        handleEdit(event, { field: 'mechanicId' });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={values['mechanicId']}
                      suggestions={customProps.mechanicList}
                      isClearable
                    />
                    {showError(
                      'mechanicId',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>)
              }
              {!hasRestrictedRole([ROLES.SERVICE_MANAGER]) &&
                <Department
                  values={values}
                  handleEdit={handleEdit}
                  i18n={i18n}
                  fetchTerminals={customProps.fetchTerminals}
                  fetchDepartments={customProps.fetchDepartments}
                  fetchSubDepartments={customProps.fetchSubDepartments}
                  regions={regions}
                  terminals={terminals}
                  departments={departments}
                  subDepartments={subDepartments}
                  disabledCriteria={disabledCriteria}
                />}
            </Styled.DVIRRightWrapper>
          )}
          {(!isDVIROnlyFlagEnabled && (!isWebfleet || (isWebfleet && isDocumentAvailable))) && (
            <div className={customProps?.newUI ? 'section-wrapper' : ''}>
              <SubHeader
                text={i18n.t('common.documents')}
                className="subheaderSection documentsHeader"
                variant="h1"
              />
              <Styled.documentWrapper>
                <UploadList
                  vehicleIdPub={values['idPub']}
                  isEdit={values['isEdit']}
                  list={uploadList.currentDocs}
                  saveDocument={customProps.saveDocument}
                  deleteDocument={customProps.deleteDocument}
                  apiStatus={customProps.apiStatus}
                  callback={customProps.callback}
                  downloadDocument={customProps.downloadDocument}
                  logBook={customProps.logBook}
                  viewOnly={isWebfleet}
                />
              </Styled.documentWrapper>
            </div>
          )}
          {window.$environment?.SERVICE_PROVIDER === 'riskTheory' && <div className={customProps?.newUI ? 'section-wrapper' : ''}>
            <SubHeader
              text={i18n.t('common.radiusSection')}
              className="subheaderSection"
              variant="h1"
            />
            <FormStyle.default.FieldsWrapper width="100%">
              <Label variant="body1">{i18n.t('companyData.radiusCompliance')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="radiusCompliance"
                  id="radiusCompliance"
                  onChange={(event) => {
                    customProps.setInitialValues({
                      ...values,
                      radiusCompliance: event
                    });
                    handleEdit(event, { field: 'radiusCompliance' });
                  }}
                  as={Select}
                  placeholder={i18n.t('common.pleaseSelect')}
                  value={values['radiusCompliance']}
                  suggestions={radiusCompliances}
                  disabled={isRiskTheoryIsFLmanagar}
                />
                {showError(
                  'radiusCompliance',
                  errors,
                  isNotValid,
                  customProps.hasApiStatus?.errorMessage
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            <SubHeader
              text={i18n.t('companyData.garagingAddress')}
              className="sub-section-header"
              variant="h3"
            />
            <FormStyle.default.FieldsWrapper width="100%">
              <Label variant="body1">{i18n.t('common.address')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="garagingAddress"
                  id="garagingAddress"
                  handleOnChange={(event) => {
                    customProps.setInitialValues({
                      ...values,
                      garagingAddress: event
                    });
                    handleEdit(event, { field: 'garagingAddress' });
                  }}
                  as={Input}
                  placeholder={i18n.t('common.address')}
                  value={values['garagingAddress']}
                  type="text"
                  disabled={isRiskTheoryIsFLmanagar}
                />
                {showError(
                  'garagingAddress',
                  errors,
                  isNotValid,
                  customProps.hasApiStatus?.errorMessage
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            <FormStyle.default.FieldsWrapper width="100%">
              <Label variant="body1">{i18n.t('common.city')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="garagingCity"
                  id="garagingCity"
                  handleOnChange={(event) => {
                    customProps.setInitialValues({
                      ...values,
                      garagingCity: event
                    });
                    handleEdit(event, { field: 'garagingCity' });
                  }}
                  as={Input}
                  placeholder={i18n.t('common.city')}
                  value={values['garagingCity']}
                  type="text"
                  disabled={isRiskTheoryIsFLmanagar}
                />
                {showError(
                  'garagingCity',
                  errors,
                  isNotValid,
                  customProps.hasApiStatus?.errorMessage
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            <FormStyle.default.FieldsWrapper width="100%">
              <Label variant="body1">{i18n.t('common.state')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="garagingState"
                  id="garagingState"
                  onChange={(event) => {
                    customProps.setInitialValues({
                      ...values,
                      garagingState: event
                    });
                    handleEdit(event, { field: 'garagingState' });
                  }}
                  as={Select}
                  placeholder={i18n.t('common.state')}
                  value={values['garagingState']}
                  suggestions={states}
                  disabled={isRiskTheoryIsFLmanagar}
                />
                {showError(
                  'garagingState',
                  errors,
                  isNotValid,
                  customProps.hasApiStatus?.errorMessage
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
            <FormStyle.default.FieldsWrapper width="100%">
              <Label variant="body1">{i18n.t('common.zipCode')}</Label>
              <FormStyle.default.TextWrapper>
                <Field
                  name="garagingZipCode"
                  id="garagingZipCode"
                  handleOnChange={(event) => {
                    customProps.setInitialValues({
                      ...values,
                      garagingZipCode: event
                    });
                    handleEdit(event, { field: 'garagingZipCode' });
                  }}
                  as={Input}
                  placeholder={i18n.t('common.zipCode')}
                  value={values['garagingZipCode']}
                  type="text"
                  disabled={isRiskTheoryIsFLmanagar}
                />
                {showError(
                  'garagingZipCode',
                  errors,
                  isNotValid,
                  customProps.hasApiStatus?.errorMessage
                )}
              </FormStyle.default.TextWrapper>
            </FormStyle.default.FieldsWrapper>
          </div>}

        </Styled.RightWrapper>}
      </Styled.Wrapper>
      {typeof customProps.hasApiStatus?.errorMessage === 'string' && (
        <Message
          type="error"
          asTags
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      <Styled.submitUser>
        <FormStyle.default.ButtonsWrapper className="submitSection">
          <Button
            className="buttonStyle"
            type="reset"
            label={i18n.t('common.cancel')}
            onClick={(e) => {
              cancelAction();
            }}
          />
          <Button
            type="submit"
            className="buttonStyle"
            label={i18n.t('common.save')}
            onClick={(e, d) => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                let validVin = hasValue(
                  customProps?.apiStatus?.status,
                  'api',
                  'isValidVIN'
                )?.errorMessage;
                if (!validVin?.vin) submitAction(data);
              });
            }}
          />
        </FormStyle.default.ButtonsWrapper>
      </Styled.submitUser>
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
