import styled from 'styled-components';
import Parent from '../Base/style';
const Container = styled(Parent.Container)`
${({ theme, type, newUI, disablePadding }) => `
.MuiTable-root {
    // border: 0;
}
.active {
    background-color: transparent !important;
    border: 0 !important;
}
.MuiTableRow-head {
    border-top: 2px solid ${theme.colors.PAGINATION_BORDER};
    border-bottom: 2px solid ${theme.colors.PAGINATION_BORDER};
    .MuiTableCell-head:first-child {
        padding-left: 17px !important;
    }
}
tbody:before {
    content: "";
    display: block;
}

.MuiTableBody-root .MuiTableCell-root:first-child {
    padding-left: 20px !important;
}
.MuiTableSortLabel-active{
    position: relative;
    padding-left: 0;
}
.MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc,
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionDesc {
    position: absolute;
    right: -25px;
    top: -6px;
    width: 1.9rem;
    svg {
      width: 1.9rem;
      height: 1.6rem;
      color: ${theme.colors.SORT_ICON};
    }
  }
  .MuiTableSortLabel-active .MuiTableSortLabel-iconDirectionAsc{
    top: -11px !important;
  }
.MuiTableSortLabel-root,
.MuiTableSortLabel-root:hover {
    color: ${theme.colors.PAGINATION_HEADER} !important;
}
.MuiTableSortLabel-root {
    position: relative;
    .MuiTableSortLabel {
        position: absolute;
        right: -22px;
        top: 0;
    }
}
.MuiTableCell-head {
    font-size: 12px;
    letter-spacing: 1;
    text-transform: uppercase;
    line-height: 1.5;
    color: ${theme.colors.PAGINATION_HEADER};
    border-bottom: 0;
    padding: 11px 10px 13px 0 !important
    ${!window?.$environment?.clutchNewUI && `
    font-weight: bold;
    `}
  }
  .MuiTableRow-root:hover {
      background-color: ${theme.colors.PAGINATION_BACKGROUND};
}
.MuiTableBody-root tr:nth-child(even) {
    background-color: ${theme.colors.PAGINATION_ALTERNATE_BACKGROUND};
}
.MuiTableBody-root .MuiTableRow-root{
    position: static !important;
}
.MuiTableBody-root .MuiTableRow-root:hover {
    border: 1px solid ${theme.colors.LOGIN_BUTTON};
}
.MuiTableBody-root tr:nth-child(odd) {
    background: ${theme.colors.PAGINATION_BACKGROUND};
}
.MuiTableRow-root .MuiTableCell-body {
   color: ${theme.colors.TABLE_ROW_COLOR};
   font-size: 13px;
}
.MuiTableBody-root .MuiTableCell-root {
    font-size: 13px;
    padding-left: 0;
    border-bottom: 0;
    ${window?.$environment?.clutchNewUI? `font-weight: bold`: `font-weight: 400`}
    padding: ${disablePadding ? '12px 0px' : '12px 10px'} !important;
    padding-left: ${disablePadding ? '0px' : window?.$environment?.clutchNewUI? '8px': '3px'} !important;
  }
.MuiTableRow-root {
    background-color: ${theme.colors.PAGINATION_HEAD_BACKGROUND ? theme.colors.PAGINATION_HEAD_BACKGROUND : theme.colors.PAGINATION_BACKGROUND};
    // .MuiTableCell-body:first-child {
    //     color: rgba(255, 255, 255, 0.8);
    // }
    .MuiTableCell-body a {
        color: ${theme.colors.LINK_COLOR}
    }
} 
`}`;
const TableContainer = styled(Parent.TableContainer)``;
const PaginationContainer = styled(Parent.PaginationContainer)`
.MuiFlatPagination-root {
    text-align: right;
    padding-right: 2%;
    padding: 0.5%;
    .MuiFlatPageButton-root:first-child MuiFlatPagination-label,
    .MuiFlatPageButton-root:last-child MuiFlatPagination-label {
        border: 1px solid #fff;
        border-radius: 3px;
        padding: 0px 7px;
        margin-right: 4px;
    }
    .MuiButton-root {
        background-color: transparent;
    }
    .MuiButton-textPrimary {
        padding: 0px;
      }
      ${({ theme }) => `.MuiButton-textPrimary {
        color: ${theme.colors.LOGIN_BUTTON};
        font-size: 13px !important;
        font-weight: 700;
    }`}
    ${({ theme }) => `.MuiButton-textSecondary{
        opacity: 0.8;
        color: ${theme.colors.TABLE_ROW_COLOR};
        font-size: 13px !important;
        font-weight: 400;
    }`}
}
`;

const Styled = { Container, TableContainer, PaginationContainer };

export default Styled;
