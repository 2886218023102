import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const MainWrapper = styled(Box)`
${({ theme }) => `
    .buttonWrap {
      display: flex;
      flex-direction: row;
      gap: 20px;
        .buttonQuotes {
        }
    }
    padding: 0px 2px;
    max-height: 90vh;
    overflow: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px gray; 
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: gray; 
      border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: gray; 
    }

    .subHeaderNew {
      background: none !important;
      padding: 0px !important;
      text-transform: none;
      text-align: center;
      scale: 1.2;
    }
    .warning {
        width: 100%;
        text-align: center;
        font-style: italic;
        font-size: 12px;
        opacity: 40%;
    }
    .descriptionHeader {
      font-size: 17px !important;
      text-align: justify;
      text-transform: none;
      color: ${theme.colors.PRIMARY_CONTRAST} !important;
    }`}
`;

const Wrapper = styled(Box)`
  ${({ theme }) => `
  .mb-5 {
    margin-bottom: 5px !important;
  }
  .coloumn {
      flex-direction: column;
      ${window.$environment?.SERVICE_PROVIDER !== 'gs' ? `margin-bottom: 8px !important;` : ''}
  }
  ${window.$environment?.SERVICE_PROVIDER !== 'gs' ? `
    .react-datepicker-wrapper {
    margin-bottom: 4px !important;
  }

  .MuiInput-root {
    margin-bottom: 4px !important;
  }
  
  ` : ``}

  .transition {
    opacity: 0;
    max-height: 0;
    margin-bottom: 0px;
    overflow: hidden;
    transition: opacity 1s ease, max-height 1s ease;
  }

  .transition.visible {
    opacity: 1;
    max-height: 500px;
    margin-bottom: 18px;
  }
    
  ${(window.$environment.APP_LAYOUT === 'SpireonNew' || window.$environment.APP_LAYOUT === 'SpireonOld') ? `
  .formWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  ` : ``}
  `}
`;


const submitUser = styled(Box)`
  .MuiBox-root {
    flex: 0 0 8%;
  }
  .MuiBox-root .MuiBox-root:first-child {
    margin-right: 2%;
  }
`;
const Styled = {
  MainWrapper,
  Wrapper,
  submitUser
};
export default Styled;
