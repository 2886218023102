import PropTypes from 'prop-types';
import React, { lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import { ROLES } from '@/config/constants/keys';
import { getQueryStringKeys, hasRestrictedRole, isdvirOnly, isInsuracePortalUser, loadPageConfig, loadStyle } from '@/utils/utils';
import ParentStyled from './style';
let Styled = loadStyle(ParentStyled, 'pages/web/Users');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.userList[0].name}`));
const Content = lazy(() => import(`@/${config.userList[1].name}`));
const TopActions =
  config.userList[0].children &&
  lazy(() => import(`${config.userList[0].children.name}`));
let timer;
const Users = (parentProps) => {
  const {
    fetchUsers,
    getFilters,
    getDocumentToken,
    history,
    i18n,
    theme,
    module,
    filterData,
    users,
    fetchPaginatedUsers
  } = parentProps;

  const basicRequest = {
    displayLength: '10',
    displayStart: '1'
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });
  const commonParentProps = { history, i18n, Styled, theme };
  const [pagination, setPagination] = useState(false);
  const userRole = getQueryStringKeys(history.location.search, 'role');
  const isServiceManager = hasRestrictedRole([ROLES.SERVICE_MANAGER])
  const RTFMuser = window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])
  const insuracePortalUserRole = isInsuracePortalUser()
  const searchConfig = {
    setPagination,
    searchPlaceholder: i18n.t('users.searchMechanic'),
    fromData: true,
    filterData,
    data: users?.list,
    query: ['full_name', 'email', 'empId']
  };
  const [filterButtons, setButtons] = useState([
    {
      label: i18n.t('users.All'),
      type: 'All',
      selected: userRole ? false : true,
      show: !isServiceManager
    },
    {
      label: i18n.t('users.drivers'),
      type: 'Driver',
      selected: userRole === 'driver',
      show: !isServiceManager
    },
    {
      label: i18n.t('users.mechanics'),
      type: 'Mechanic',
      selected: userRole === 'mechanic',
      show: (isdvirOnly() && !hasRestrictedRole(['FLMANAGER'])) || RTFMuser ||
        window.$environment.CURRENT_LAYOUT !== 'Clutch' || window.$environment.SERVICE_PROVIDER == 'simplex' || window.$environment.SERVICE_PROVIDER == 'cyntrx' || window.$environment.SERVICE_PROVIDER === 'nauto' || window.$environment.SERVICE_PROVIDER == 'trackit' || window.$environment.SERVICE_PROVIDER == 'ft' || window.$environment.SERVICE_PROVIDER == 'hcss' || window.$environment.SERVICE_PROVIDER == 'patriot' 
      // || window.$environment.SERVICE_PROVIDER === 'clutch'
    },
    {
      label: i18n.t('users.fleetManager'),
      type: 'Fleet Manager',
      selected: userRole === 'fleetmanager',
      show: !isServiceManager && !insuracePortalUserRole
    },
    {
      label: i18n.t('common.admin'),
      type: 'Admin',
      selected: userRole === 'admin',
      show: !isServiceManager && !insuracePortalUserRole
    },
    {
      label: i18n.t('users.underWriters'),
      type: 'Under Writer',
      selected: userRole === 'underwriter',
      show: insuracePortalUserRole
    },
    {
      label: i18n.t('users.lossControllers'),
      type: 'Loss Control',
      selected: userRole === 'loss-control',
      show: insuracePortalUserRole
    },
    {
      label: i18n.t('users.claimManagers'),
      type: 'Claim Adjuster',
      selected: userRole === 'claim-adjuster',
      show: insuracePortalUserRole
    },
  ]);
  const viewOnly = hasRestrictedRole([ROLES.VIEW_ONLY_FM])
  useEffect(() => {
    if (insuracePortalUserRole) {
      fetchUsers({
        apiId: 'fetchUsers',
        ...(module === 'mechanicList' ? { userRole: 'MECHANIC' } : {})
      })
    } else {
      fetchPaginatedUsers({
        apiId: 'fetchUsers',
        data: { request: module === 'mechanicList' ? { ...request, search: 'MECHANIC' } : request },
      });
    }

    getFilters({ apiId: 'fetchFilters' });
    getDocumentToken({ apiId: 'getDocToken' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchPaginateApi = 'fetchUsersPaginateApi'

  const filterSuccess = (e) => {
    if (insuracePortalUserRole) {
      filterData(e)
    } else {
      clearTimeout(timer);
      timer = setTimeout(() => {
        fetchPaginatedUsers({
          apiId: 'fetchSearchUserApi',
          data: { request: { ...request, search: e } },
        });
      }, 500);
    }

  }

  useEffect(() => {
    if(!insuracePortalUserRole && JSON.stringify(request) != JSON.stringify(basicRequest)){
      fetchPaginatedUsers({
        apiId: fetchPaginateApi,
        data: { request: request },
      });
    }    
  }, [request]);

  const sortColumnMapping = {
    email: "email",
    full_name: "fullName",
    empId: "empId",
    status: "status"
  }
  const [removeData, setremoveData] = useState('false')
  const changeRequest = (fields) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    setRequest({ ...basicRequest, ...fields });
  };
  const setFilterData = (role) => {
    let search = ''
    switch (role) {
      case 'All':
        search = ''
        break
      case 'Driver':
        search = 'DRIVER'
        break
      case 'Fleet Manager':
        search = 'FLMANAGER'
        break
      case 'Mechanic':
        search = 'MECHANIC'
        break
      case 'Admin':
        search = 'ADMIN'
        break
      case 'Under Writer':
        search = 'UNDER_WRITER'
        break
      case 'Loss Control':
        search = 'LOSS_CONTROL'
        break
      case 'Claim Adjuster':
        search = 'CLAIM_ADJUSTER'
        break
      default:
        search = ''
        break
    }
    setRequest({ ...basicRequest, search })
  }
  return (
    <>
      <RenderComponent
        {...config.userList[0]}
        parentProps={{
          ...parentProps,
          pagination,
          setPagination,
          setButtons,
          filterButtons,
          setremoveData,
          filterSuccess,
          removeData
        }}
        {...(module === 'mechanicList' ? {
          header: 'users.mechanicListHeading'

        } : {})}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
        searchConfig={
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
            ? searchConfig
            : null
        }
      />
      <RenderComponent
        {...config.userList[1]}
        parentProps={{
          ...parentProps,
          changeRequest,
          request,
          setFilterData,
          filterSuccess
        }}
        commonParentProps={commonParentProps}
        component={Content}
        filterButtons={filterButtons}
        setButtons={setButtons}
        pagination={pagination}
        setPagination={setPagination}
        setremoveData={setremoveData}
        removeData={removeData}
        viewOnly={viewOnly}
        viewExportOptions={module !== 'mechanicList'}
        showFilters={module !== 'mechanicList'}
        showAllUsers={module !== 'mechanicList'}
        isMechanicUser={module === 'mechanicList'}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    users: state.users,
    filters: state.filters,
    logBook: state.logBook
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchUsers: (data) => dispatch.users.fetchData(data),
    fetchPaginatedUsers: (data) => dispatch.users.fetchPaginatedUsers(data),
    handleDeleteAlert: (data) => dispatch.users.handleDeleteAlert(data),
    handleResetPassword: (data) => dispatch.users.handleResetPassword(data),
    handleRestoreAlert: (data) => dispatch.users.handleRestoreAlert(data),
    filterData: (data) => dispatch.users.filterSuccess(data),
    handleExportUser: (data) => dispatch.users.handleExportUser(data),
    handleImportUser: (data) => dispatch.users.handleImportUser(data),
    handleExportUserTemplate: (data) =>
      dispatch.users.handleExportUserTemplate(data),
    getFilters: (data) => dispatch.filters.fetchData(data),
    getDocumentToken: (data) => dispatch.logBook.documentToken(data)
  };
};

export default connect(mapState, mapDispatch)(Users);

Users.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  fetchPaginatedUsers: PropTypes.func.isRequired,
  handleDeleteAlert: PropTypes.func.isRequired,
  handleResetPassword: PropTypes.func.isRequired,
  handleRestoreAlert: PropTypes.func.isRequired,
  filterData: PropTypes.func.isRequired,
  handleExportUser: PropTypes.func.isRequired,
  handleImportUser: PropTypes.func.isRequired,
  handleExportUserTemplate: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  getFilters: PropTypes.func.isRequired
};
