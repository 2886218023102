import { hasEnabledFeature } from "@/utils/utils";
const isDownloadCenterEnabled = hasEnabledFeature('downloadCenterEnabled')
const leftMenu = [
  {
    icon: 'dashboard.svg',
    label: 'leftMenu.dashboard',
    path: '/dashboard',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'dashboard'
  },
  {
    icon: 'logs.svg',
    label: 'leftMenu.logs',
    roles: ['FLMANAGER', 'DRIVER'],
    path: '/logs/list',
    feature: 'log'
  },
  {
    icon: 'user.svg',
    label: 'leftMenu.users',
    roles: ['FLMANAGER'],
    path: '/user/list',
    feature: 'user'
  },
  {
    icon: 'vehicle.svg',
    label: 'leftMenu.vehicles',
    roles: ['FLMANAGER'],
    path: '/vehicle/list',
    feature: 'vehicle'
  },
  {
    icon: 'reports.svg',
    label: 'leftMenu.reports',
    path: '/report',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'reportsEnabled',
    feature: 'report'
  },
  {
    icon: 'company.svg',
    label: 'leftMenu.companyData',
    path: '/company/edit',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'companyDataEdit',
  },
  {
    icon: 'dotAudit.svg',
    label: 'leftMenu.DOTAudit',
    roles: ['FLMANAGER'],
    path: '/logs/fmcsaLogs',
    feature: 'fmcsa'
  },
  // {
  //   icon: 'accident.svg',
  //   label: 'leftMenu.accidents',
  //   roles: ['FLMANAGER'],
  //   path: '/accidents/list'
  // },
  // {
  //   icon: 'repair.svg',
  //   label: 'leftMenu.repairMaintenance',
  //   roles: ['FLMANAGER'],
  //   path: '/test'
  // },
  // {
  //   icon: 'inspection.svg',
  //   label: 'leftMenu.inspections',
  //   roles: ['FLMANAGER'],
  //   path: '/test'
  // },
  // {
  //   icon: 'filter.svg',
  //   label: 'leftMenu.filters',
  //   roles: ['FLMANAGER'],
  //   isFilter: true
  // },
  // {
  //   icon: 'departments.svg',
  //   label: 'common.departments',
  //   roles: ['FLMANAGER'],
  //   path: '/department/list/default'
  // },
  // {
  //   icon: 'usdot.svg',
  //   label: 'leftMenu.USDOT',
  //   roles: ['FLMANAGER', 'ADMIN'],
  //   module: 'usDotManagementenabled',
  //   path: '/usdot/management'
  // },
  {
    icon: 'documents.svg',
    label: 'leftMenu.documents',
    roles: ['FLMANAGER'],
    path: '/company/document/edit'
  },
  {
    icon: 'dailyDocuments.svg',
    label: 'leftMenu.dailyDocuments',
    roles: ['FLMANAGER'],
    path: '/dailyDocuments/list',
    feature: 'dailyDocuments'
  },
  {
    icon: 'downloadCenterMenu.svg',
    label: 'leftMenu.downloadCenter',
    roles: ['FLMANAGER', 'ADMIN'],
    path: '/downloadCenter/list',
    module: 'downloadCenterEnabled',
  },
  {
    icon: 'fuel.svg',
    label: 'leftMenu.fuelReport',
    roles: ['FLMANAGER'],
    path: '/fuel/list',
    feature: 'fuel'
  },
  {
    icon: 'policy.svg',
    label: 'leftMenu.policiesProcedures',
    path: '/policy/edit',
    roles: ['FLMANAGER', 'ADMIN'],
    feature: 'policy'
  },
  // {
  //   icon: 'ifta.svg',
  //   label: 'leftMenu.IFTAReport',
  //   roles: ['FLMANAGER'],
  //   path: '/test'
  // },
  // {
  //   icon: 'reportIcon.svg',
  //   label: '',
  //   roles: ['FLMANAGER'],
  //   module: 'reportsEnabled',
  //   path: '/report'
  // },
  // {
  //   icon: 'developer.svg',
  //   label: 'leftMenu.developerRequests',
  //   roles: ['FLMANAGER'],
  //   path: '/alerts/requests'
  // },
  // {
  //   icon: 'feedback.svg',
  //   label: 'leftMenu.feedback',
  //   roles: ['FLMANAGER'],
  //   path: '/feedback'
  // }
];
export default leftMenu;
